import { Fragment } from "react";
import { teamListData } from "./team-list/team.data";
import { TeamList } from "./team-list";
import { Box, Typography,Grid } from "@mui/material";

export const Team = () => {
  return (
    <>
      <Box style={{ margin: "50px 50px" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "11px",
          }}
        >
          <span className="heading-top">Meet Our Team Members</span>
        </Box>
        <Typography className="heading-typo" style={{textAlign:"center"}}>
          A diverse team of experts united by innovation and customer success.
          With backgrounds in software development, marketing, sales, and
          customer support, we deliver exceptional solutions with passion.
        </Typography>
      </Box>
      <Box maxWidth="1200px" margin="auto" padding={2}>
      <Grid container spacing={3}>
        {teamListData?.length ? (
          teamListData.map((team, index) => (
            <Fragment key={index}>
              <Grid item xs={12} sm={6} md={3}>
                <TeamList data={team} />
              </Grid>
            </Fragment>
          ))
        ) : (
          <></>
        )}
      </Grid>
    </Box>
    </>
  );
};
