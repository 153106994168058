import React from "react";
import { PolicyFeature } from "../../Features/PolicyFeature/PolicyFeature";
import { AIR_APPLE_CART_INTRO, AIR_APPLE_CART_NAME } from "../../../config";
export const privacyPolicy = [
  {
    id: 1,
    heading: "Information We Collect",
    description:
      "We collect information you provide when you use our platform, including:",
    list: [
      {
        heading: "Personal Information",
        detail:
          "Name, email, and contact details you provide while signing up.",
      },
      {
        heading: "Usage Information",
        detail:
          "Data related to your interactions with our platform, such as pages visited and actions taken.",
      },
    ],
  },
  {
    id: 2,
    heading: "How We Use Your Information",
    description: "We use your information to: ",
    list: [
      {
        detail: "Provide and improve our services.",
      },
      {
        detail: "customise your experience and offer tailored content. ",
      },
      {
        detail:
          "Communicate with you, respond to inquiries, and provide support. ",
      },
      {
        detail:
          "Send you updates, promotions, and relevant content if you opt in. ",
      },
    ],
  },
  {
    id: 3,
    heading: "Data Protection and Security",
    description: "We take measures to protect your data: ",
    list: [
      {
        heading: "Data Encryption",
        detail:
          "Your data is transmitted and stored securely using encryption protocols.",
      },
      {
        heading: "Access Control",
        detail: "Your data is accessible only to authorised personnel.",
      },
      {
        heading: "Infrastructure Security",
        detail: "We use secure hosting and data storage solutions.",
      },
    ],
  },
  {
    id: 4,
    heading: "Data Sharing and Third Parties",
    description:
      "We do not sell, rent, or share your data with third parties without your explicit consent. We may share your data with trusted service providers who assist us in delivering our services. ",
  },
  {
    id: 5,
    heading: "Your Data Privacy Rights",
    description:
      "You have the right to access, modify, or delete your data. You can also object to the processing of your data and withdraw your consent. ",
  },
  {
    id: 6,
    heading: "Cookies and Tracking",
    description:
      "We use cookies and similar technologies to enhance your experience and gather usage information. You can manage your cookie preferences through your browser settings. ",
  },
  {
    id: 7,
    heading: "Changes to this Privacy Policy",
    description:
      "We may update this Privacy Policy to reflect changes in our practices. We will notify you of any significant changes and seek your consent if required by law. ",
  },
  {
    id: 8,
    heading: "Contact Us",
    greenDescription: "info@airapplecart.co.uk.",
    description:
      "If you have questions or concerns about your data, please contact us at ",
    lastDesc:
      " By using Air Applecart, you acknowledge and agree to the practices outlined in this Privacy Policy. Your privacy matters to us, and we are committed to ensuring the security and confidentiality of your personal information.",
  },
];
export const PrivacyPolicyPage = () => {
  return (
    <>
      <PolicyFeature
        pageData={privacyPolicy}
        heading="Air Applecart Privacy Policy"
        description={`${AIR_APPLE_CART_INTRO} We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our CRM platform and services. By using ${AIR_APPLE_CART_NAME}, you agree to the practices described in this policy. `}
      />
    </>
  );
};
