import React from 'react'
import { ResetForm } from './ResetForm'
import { Accounts } from '../../Common/Accounts/Accounts'

export const ResetPass = () => {
  return (
    <Accounts>
        <ResetForm />
    </Accounts>
  )
}
