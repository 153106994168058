import { Box, Card, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import './contact-us.scss';
import { info_data } from './contact-us-data';

export const ContactInfo = () => {
  return (
    <React.Fragment>
        <Container maxWidth="xl" sx={{bgcolor:'#fff', marginTop: '100px', marginBottom: '70px'}}>
            <Box sx={{textAlign: 'center'}}>
                <Typography className='contact-info-title' mb="12px">Contact Information</Typography>
                <Typography className='contact-info-sub-title'>Have any questions? We'd love to hear from you.</Typography>
            </Box>
            <Box className="contact-info-cards-sect">
                <Grid container>
                {
                    info_data?.map((data,index) => (
                        <Grid xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Card className="card-info" variant='outlined'>
                            <div className='info-icons' style={{background: '#f7f7f9'}}>
                                <img src={data?.icon} alt='Icon'/>
                            </div>
                            <Box sx={{mb:'36px',display:'flex',flexDirection:'column',alignItems:'center'}}>
                                <Typography mb='16px' className="info-title">{data?.title}</Typography>
                                <Typography maxWidth="335px" height="84px" className="info-description">{data?.description}</Typography>
                            </Box>
                            <hr color='#E5E7EB' style={{width: '90%'}}/>
                            <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                <div>
                                <Typography className="info-email"> <img style={{position: 'relative', top: '5px'}} src='/assets/Contactus/sms.svg' /> &nbsp; {data?.email}</Typography>
                                {/* <Typography className="info-number"> <img style={{position: 'relative', top: '5px'}} src='/assets/Contactus/call.svg' /> &nbsp; {data?.number}</Typography> */}
                                </div>
                            </Box>
                        </Card>
                    </Grid>
                    ))
                }
                    
                </Grid>
            </Box>
        </Container>
    </React.Fragment>
  )
}
