export const accordian_data = [
    {
        id: 1,
        title: 'What is Air Applecart, and what does it do ?',
        desc: 'Air Applecart is an all-in-one CRM solution designed for small businesses. It helps streamline sales processes, marketing efforts, and customer service all in one platform. '
    },
    {
        id: 2,
        title: 'How can Air Applecart benefit my small business?',
        desc: 'Air Applecart can boost your business by improving efficiency, enhancing customer relationships, and providing tools for better marketing and sales management. '
    },
    {
        id: 3,
        title: 'Is Air Applecart suitable for my business size? ',
        desc: 'Yes! Air Applecart is specifically tailored for small businesses looking to optimise their operations. '
    },
    {
        id: 4,
        title: 'What features does Air Applecart offer for sales teams?',
        desc: 'Air Applecart offers sales teams tools for lead management, contact tracking, sales pipeline management, and reporting to help them close deals more effectively. '
    },
    {
        id: 5,
        title: 'How can Air Applecart assist with marketing efforts? ',
        desc: 'Air Applecart includes marketing automation features, email marketing, campaign management, and analytics to help you reach and engage with your target audience. '
    },
    {
        id: 6,
        title: 'Can I integrate Air Applecart with other software we use, like QuickBooks? ',
        desc: 'Yes, Air Applecart offers seamless integration with various third-party software, including QuickBooks, to simplify your accounting and financial management. '
    },
    {
        id: 7,
        title: 'Is Air Applecart user-friendly? ',
        desc: 'Absolutely! Our platform is designed with ease of use in mind, and we provide training and support to help you get started. '
    },
    {
        id: 8,
        title: 'What type of customer support does Air Applecart offer? ',
        desc: 'We offer a range of customer support options, including email, live chat, and phone support, to assist you with any questions or issues. '
    },
    {
        id: 9,
        title: 'Is there a free trial available for Air Applecart? ',
        desc: 'Yes, we offer a free trial period so you can explore our platform and see how it can benefit your business before making a commitment.'
    },
    {
        id: 10,
        title: 'How do I get started with Air Applecart? ',
        desc: 'Getting started is easy. Sign up for a free trial, verify your identity with Identity Gram and our onboarding process will guide you through the setup.'
    },
]