import { Box, Container, Typography } from '@mui/material'
import React from 'react';
import './contact-us.scss';
import { AIR_APPLE_CART_OFFICE_ADDRESS } from '../../../config';

export const VisitUs = () => {
  return (
    <Box>
        <Container maxWidth="xl">
            <Box>
                <div className='cart2-ellipse'>
                    <img src={'assets/Home/Ellipse 22.svg'} />
                </div>
            </Box>
            <div className='visit-us-bg'>
                <Box sx={{display: 'flex', justifyContent: 'center', paddingTop: '64px'}}>
                    <Box className='visit-icon'><img src='/assets/Contactus/location.png' alt='Location Icon'/></Box>
                </Box>
                <br/>
                <Typography className='visit-title'>Visit Us</Typography>
                <br/>
                <Typography className='visit-address'> <span>Email address:</span> info@airapplecart.co.uk </Typography>
                <Typography className='visit-address'> <span>Office address:</span> {AIR_APPLE_CART_OFFICE_ADDRESS}</Typography>
                {/* <Typography className='visit-address'> <span>Trading address:</span> 1 Poultry, London EC2R 8EJ </Typography> */}
                <br/>
                <Typography className='visit-description'>We're excited to hear from you and assist you on your journey with Air Applecart. Your feedback and engagement are essential to us, and we look forward to connecting with you soon! </Typography>
            </div>
        </Container>
    </Box>
  )
}
