import { Box, Button, Card, Container, Typography } from '@mui/material';
import React from 'react';
import './Forgot.scss';

export const ForgotForm = () => {
    const [SendEmail, setSendEmail] = React.useState(false);
    const Submit = () => {
        setSendEmail(!SendEmail);
    }
  return (
    <Container>
      <Box className="signin-form">
        <Typography className="welcome-title">
         Forgot Password
        </Typography>
        {
            !SendEmail && (
                <Typography className="get-started-title">Let’s Get Start Enter the email associated with your account and we’ll send an email with instructions to reset your password!</Typography>
            )
        }
        <br />
        <Card
          className="sign-form-card"
          variant="outlined"
        >
        {
            !SendEmail ? (
                <React.Fragment>
                    <Box>
                        <label className='input-label'>
                            Email <span className="form-esteric">*</span>
                        </label>
                        <input className="input-field" placeholder="Enter Email" />
                        <br />
                    </Box>
                    <Button
                        sx={{
                        width: "100%",
                        background: "#38CAB5",
                        color: "#fff",
                        padding: "10px",
                        fontSize: '16px',
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '500',
                        textTransform: "capitalize",
                        "&:hover": {
                            backgroundColor: "#38CAB5", // Change the background color on hover to light green
                        },
                        }}
                        variant="contained"
                        onClick={Submit}
                    >
                        Continue
                    </Button>
                </React.Fragment>
            ) : (
                    <Box className='email-sent'>
                        <Typography className='email-sent1'>Email Sent</Typography>
                        <br/>
                        <Typography className='email-sent2'>Password reset email has been sent to registered email.</Typography>
                    </Box>
            )
        }
           
          <br/>
        </Card>
      </Box>
    </Container>
  )
}
