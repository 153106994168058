export const hero = [
    {
        title: 'Introducing Stenogofer',
        section: 'Elevating Communication and Collaboration',
        description: 'Experience a new level of communication and collaboration with Stenogofer, now seamlessly integrated into Air Applecart. This cutting-edge feature is designed to revolutionise the way your teams interact, ensuring efficient communication and streamlined collaboration, all within the familiar Air Applecart environment.'
    }
];

export const features = [
    {
        id: 1,
        title: 'Real-time Messaging',
        description: 'Stay connected with your team members through instant messaging. Share updates, ideas, and information in real time to keep everyone in sync.',
        img: '/assets/Products/status-up.svg',
        icon_color: '#ECFFFC',
    },
    {
        id: 2,
        title: 'File Sharing',
        description: 'Easily share files, documents, and resources within the chat. No need to switch between platforms – everything is conveniently available within Stenogofer.',
        img: '/assets/Products/announcement-02.svg',
        icon_color: '#F2F9FC'
    },
    {
        id: 3,
        title: 'Threaded Conversations',
        description: 'Keep conversations organisedby using threaded discussions. Follow the flow of discussions and maintain context effortlessly.',
        img: '/assets/Products/chart.svg',
        icon_color: '#FFF4F6'
    },
    {
        id: 4,
        title: 'Direct Messaging',
        description: 'Reach out to individual team members for one-on-one conversations. Enhance personal connections and ensure efficient communication.',
        img: '/assets/Products/social-media 1.png',
        icon_color: '#FBF9FF'
    },
    {
        id: 5,
        title: 'Mobile Access',
        description: 'Stay connected on the go with the Stenogofer mobile app. Access chats and collaborate seamlessly, even when you are not at your desk.',
        img: '/assets/Products/magnetism 1.svg',
        icon_color: '#FFF6F1'
    },
    {
        id: 6,
        title: 'Team Channels',
        description: 'Create dedicated channels for different projects, teams, or topics. organise discussions and collaborations for improved focus and clarity.',
        img: '/assets/Products/personalisation 1.svg',
        icon_color: '#FEFEEE'
    },
];

export const benefits = [
    {
        description: `Elevate your communication and collaboration game with Stenogofer, seamlessly integrated into Air Applecart. With this game-changing feature, you can streamline your communication, keep projects on track, and enhance teamwork – all within a single, unified platform. Embrace the future of efficient collaboration with Stenogofer and Air Applecart.`,
        image: '/assets/Products/air-stenogofer-benefit.svg',
        benefit: [
            {
                id: 1,
                title: 'Enhanced Collaboration',
                description: `Foster teamwork and collaboration among team members regardless of their physical location. Keep everyone engaged and aligned.`,
                img: '/assets/Products/status-up.svg',
            },
            {
                id: 2,
                title: 'centralised Communication',
                description: `Streamline communication by having all discussions and file sharing in one place. Avoid scattered information and confusion.`,
                img: '/assets/Products/decision-making 1.svg',
            },
            {
                id: 3,
                title: 'Increased Productivity',
                description: `Eliminate the need for switching between platforms. Access chats and files directly within Air Applecart, saving time and effort.`,
                img: '/assets/Products/people.svg',
            },
            {
                id: 4,
                title: 'Effortless Search',
                description: `Ensure compliance and minimise the risk of errors with standardised templates and automated workflows.`,
                img: '/assets/Products/data.svg',
            },
        ]
    }
];

export const joinUs = {
    title: 'Ready to Amplify Your Collaboration?',
    description: `Unlock the potential of Stenogofer and experience a new era of seamless communication and collaboration. Explore the power of Air Applecart's integration and transform the way your teams work together. Embrace the future of efficient communication with Stenogofer – an essential tool for your business's success.`,
    img: '/assets/Products/marketer-joinUs.svg',
}