import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { BASE_FE_APP_URL } from '../../../../config';


export const PlanTable = () => {
    return (
        <>
            <TableContainer sx={{ mb: '120px', mt: '40px' }}>
                <Table sx={{ minWidth: 650, '.MuiTableCell-root': { fontFamily: `"Plus Jakarta Sans", sans-serif`, p: '20px 32px', border: '1px solid #E6E9F5 !important' } }} aria-label="simple table">
                    <TableHead >
                        <TableRow sx={{ '.MuiTableCell-head': { bgcolor: '#F9FAFB', fontSize: '18px', fontWeight: '600', } }}>
                            <TableCell sx={{ borderRadius: '8px 0px 0px 0px' }}>Product Name</TableCell>
                            <TableCell >Free Trial</TableCell>
                            <TableCell >Growth</TableCell>
                            <TableCell sx={{ borderRadius: '0px 8px 0px 0px' }}>Pro</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell rowSpan={2} className='flex flex-center' >
                                <Box sx={{ fontSize: '18px', fontWeight: 600, color: '#1F2937', my: '12px' }}>
                                    Air Sales
                                </Box>
                                <Box sx={{ color: '#6B7280', lineHeight: '24px', width: '240px' }}>
                                    Air Sales is your ultimate partner for sales success. We're dedicated to helping you elevate your sales strategies and achieve remarkable growth
                                </Box>
                            </TableCell>
                            <TableCell className='flex flex-center'>
                                <Box flexDirection="column" className="flex flex-center">
                                    <Box sx={{ fontSize: '32px', fontWeight: 600, color: '#374151', mb: '35px' }}>
                                        £20<span style={{ fontSize: '14px', color: '#6B7280' }}>/Month</span>
                                    </Box>
                                    <Button sx={{ textTransform: 'capitalize', fontFamily: `"Plus Jakarta Sans", sans-serif`, fontSize: '14px !important', fontWeight: 500, color: '#fff', bgcolor: "#38CAB5", width: '108px', height: '38px', borderRadius: '4px', '&:hover': { bgcolor: "#38CAB5" } }}>
                                        Select Plan
                                    </Button>
                                   
                                </Box>
                            </TableCell>
                            <TableCell className='flex flex-center'>
                                <Box flexDirection="column" className="flex flex-center">
                                    <Box sx={{ fontSize: '32px', fontWeight: 600, color: '#374151', mb: '35px' }}>
                                        £300<span style={{ fontSize: '14px', color: '#6B7280' }}>/Month</span>
                                    </Box>
                                    <Button sx={{ textTransform: 'capitalize', fontFamily: `"Plus Jakarta Sans", sans-serif`, fontSize: '14px !important', fontWeight: 500, color: '#fff', bgcolor: "#38CAB5", width: '108px', height: '38px', borderRadius: '4px', '&:hover': { bgcolor: "#38CAB5" } }}>
                                        Select Plan
                                    </Button>
                                </Box>
                            </TableCell>
                            <TableCell className='flex flex-center'>
                                <Box flexDirection="column" className="flex flex-center">
                                    <Box sx={{ fontSize: '32px', fontWeight: 600, color: '#374151', mb: '35px' }}>
                                        £450<span style={{ fontSize: '14px', color: '#6B7280' }}>/Month</span>
                                    </Box>
                                    <Button sx={{ textTransform: 'capitalize', fontFamily: `"Plus Jakarta Sans", sans-serif`, fontSize: '14px !important', fontWeight: 500, color: '#fff', bgcolor: "#38CAB5", width: '108px', height: '38px', borderRadius: '4px', '&:hover': { bgcolor: "#38CAB5" } }}>
                                        Select Plan
                                    </Button>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '14px', textAlign: 'center', }}>
                                    Essential tools to put your customers first and deliver authethic services
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '14px', textAlign: 'center', }}>
                                    Comprehensive help desk software to scale self-service and automation
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '14px', textAlign: 'center', }}>
                                    Our most powerful help desk software for advanced control and flexibility
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '18px', }}>
                                    Users
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '14px', textAlign: 'center', fontWeight: 600 }}>
                                    Includes 2 paid users
                                </Box>
                                <Box sx={{ color: '#6B7280', fontSize: '14px', textAlign: 'center', }}>
                                    £10/ Month per additional user
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '14px', textAlign: 'center', fontWeight: 600 }}>
                                    Include 5 paid users
                                </Box>
                                <Box sx={{ color: '#6B7280', fontSize: '14px', textAlign: 'center', }}>
                                    £100/ Month per additional user
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '14px', textAlign: 'center', fontWeight: 600 }}>
                                    Include 10 paid users
                                </Box>
                                <Box sx={{ color: '#6B7280', fontSize: '14px', textAlign: 'center', }}>
                                    £120/Month per additional user
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '18px', }}>
                                    Add User
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center' }}>
                                    <div className="minus pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path d="M15.9993 2.66669C8.65268 2.66669 2.66602 8.65335 2.66602 16C2.66602 23.3467 8.65268 29.3334 15.9993 29.3334C23.346 29.3334 29.3327 23.3467 29.3327 16C29.3327 8.65335 23.346 2.66669 15.9993 2.66669ZM21.226 17H10.5593C10.0127 17 9.55935 16.5467 9.55935 16C9.55935 15.4534 10.0127 15 10.5593 15H21.226C21.7727 15 22.226 15.4534 22.226 16C22.226 16.5467 21.786 17 21.226 17Z" fill="#D1D5DB" />
                                        </svg>
                                    </div>
                                    <div className="text-field">
                                        <input className='candy' value={0} />
                                    </div>
                                    <div className="plus pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path d="M15.9993 2.66669C8.65268 2.66669 2.66602 8.65335 2.66602 16C2.66602 23.3467 8.65268 29.3334 15.9993 29.3334C23.346 29.3334 29.3327 23.3467 29.3327 16C29.3327 8.65335 23.346 2.66669 15.9993 2.66669ZM21.3327 17H16.9993V21.3334C16.9993 21.88 16.546 22.3334 15.9993 22.3334C15.4527 22.3334 14.9993 21.88 14.9993 21.3334V17H10.666C10.1193 17 9.66602 16.5467 9.66602 16C9.66602 15.4534 10.1193 15 10.666 15H14.9993V10.6667C14.9993 10.12 15.4527 9.66669 15.9993 9.66669C16.546 9.66669 16.9993 10.12 16.9993 10.6667V15H21.3327C21.8793 15 22.3327 15.4534 22.3327 16C22.3327 16.5467 21.8793 17 21.3327 17Z" fill="#38CAB5" />
                                        </svg>
                                    </div>
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center' }}>
                                    <div className="minus pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path d="M15.9993 2.66669C8.65268 2.66669 2.66602 8.65335 2.66602 16C2.66602 23.3467 8.65268 29.3334 15.9993 29.3334C23.346 29.3334 29.3327 23.3467 29.3327 16C29.3327 8.65335 23.346 2.66669 15.9993 2.66669ZM21.226 17H10.5593C10.0127 17 9.55935 16.5467 9.55935 16C9.55935 15.4534 10.0127 15 10.5593 15H21.226C21.7727 15 22.226 15.4534 22.226 16C22.226 16.5467 21.786 17 21.226 17Z" fill="#D1D5DB" />
                                        </svg>
                                    </div>
                                    <div className="text-field">
                                        <input className='candy' value={0} />
                                    </div>
                                    <div className="plus pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path d="M15.9993 2.66669C8.65268 2.66669 2.66602 8.65335 2.66602 16C2.66602 23.3467 8.65268 29.3334 15.9993 29.3334C23.346 29.3334 29.3327 23.3467 29.3327 16C29.3327 8.65335 23.346 2.66669 15.9993 2.66669ZM21.3327 17H16.9993V21.3334C16.9993 21.88 16.546 22.3334 15.9993 22.3334C15.4527 22.3334 14.9993 21.88 14.9993 21.3334V17H10.666C10.1193 17 9.66602 16.5467 9.66602 16C9.66602 15.4534 10.1193 15 10.666 15H14.9993V10.6667C14.9993 10.12 15.4527 9.66669 15.9993 9.66669C16.546 9.66669 16.9993 10.12 16.9993 10.6667V15H21.3327C21.8793 15 22.3327 15.4534 22.3327 16C22.3327 16.5467 21.8793 17 21.3327 17Z" fill="#38CAB5" />
                                        </svg>
                                    </div>
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center' }}>
                                    <div className="minus pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path d="M15.9993 2.66669C8.65268 2.66669 2.66602 8.65335 2.66602 16C2.66602 23.3467 8.65268 29.3334 15.9993 29.3334C23.346 29.3334 29.3327 23.3467 29.3327 16C29.3327 8.65335 23.346 2.66669 15.9993 2.66669ZM21.226 17H10.5593C10.0127 17 9.55935 16.5467 9.55935 16C9.55935 15.4534 10.0127 15 10.5593 15H21.226C21.7727 15 22.226 15.4534 22.226 16C22.226 16.5467 21.786 17 21.226 17Z" fill="#D1D5DB" />
                                        </svg>
                                    </div>
                                    <div className="text-field">
                                        <input className='candy' value={0} />
                                    </div>
                                    <div className="plus pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path d="M15.9993 2.66669C8.65268 2.66669 2.66602 8.65335 2.66602 16C2.66602 23.3467 8.65268 29.3334 15.9993 29.3334C23.346 29.3334 29.3327 23.3467 29.3327 16C29.3327 8.65335 23.346 2.66669 15.9993 2.66669ZM21.3327 17H16.9993V21.3334C16.9993 21.88 16.546 22.3334 15.9993 22.3334C15.4527 22.3334 14.9993 21.88 14.9993 21.3334V17H10.666C10.1193 17 9.66602 16.5467 9.66602 16C9.66602 15.4534 10.1193 15 10.666 15H14.9993V10.6667C14.9993 10.12 15.4527 9.66669 15.9993 9.66669C16.546 9.66669 16.9993 10.12 16.9993 10.6667V15H21.3327C21.8793 15 22.3327 15.4534 22.3327 16C22.3327 16.5467 21.8793 17 21.3327 17Z" fill="#38CAB5" />
                                        </svg>
                                    </div>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >
                                <Box sx={{ color: '#111827', fontSize: '18px', fontWeight: 600 }}>
                                    Sales Activities
                                </Box>
                            </TableCell>
                            <TableCell >

                            </TableCell>
                            <TableCell >

                            </TableCell>
                            <TableCell >

                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '18px', }}>
                                    Tasks, Appointments and Notes
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#47B263" />
                                    </svg>
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#47B263" />
                                    </svg>
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#47B263" />
                                    </svg>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '18px', }}>
                                    Custom Sales Activites
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#47B263" />
                                    </svg>
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#47B263" />
                                    </svg>
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#47B263" />
                                    </svg>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >
                                <Box sx={{ color: '#111827', fontSize: '18px', fontWeight: 600 }}>
                                    CRM Customization
                                </Box>
                            </TableCell>
                            <TableCell >

                            </TableCell>
                            <TableCell >

                            </TableCell>
                            <TableCell >

                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '18px', }}>
                                    Workflows
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '18px', textAlign:'center' }}>
                                    20
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '18px',  textAlign:'center'}}>
                                    50
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={{ color: '#6B7280', fontSize: '18px',  textAlign:'center'}}>
                                    100
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
