import { Box, Container } from '@mui/material'
import React from 'react'
import { Hero } from '../../Common/Products/hero-section/Hero'
import { PolicyHPL } from '../../Common/PolicyHPL/PolicyHPL';
export const PolicyFeature = ({ pageData, heading, description }) => {
    return (
        <>
            <Container>
                <Hero hero={[{ title: heading, description: description }]} page={true} />
                <Box>
                    <PolicyHPL data={pageData} />
                </Box>
            </Container>
        </>
    )
}
