export const teamListData = [
    {
        srcImage:'assets/Aboutus/AAC - Shan ul Haq.jpg',
        name:'Shan Ul Haq',
        designation:'Product Owner '
    },
    {
        srcImage:'assets/Aboutus/Azam Altaf.jpeg',
        name:'Azam Altaf',
        designation:'Compliance Officer'
    },
    {
        srcImage:'assets/Aboutus/AAC - Adil Shahid.jpg',
        name:'Adil Shahid',
        designation:'Project Manager'
    },
    {
        srcImage:'assets/Aboutus/AAC - Florence Alice Mitchell.jpg',
        name:'Florence Alice Mitchell',
        designation:'Marketing Manager'
    },
    
    {
        srcImage:'assets/Aboutus/AAC -Poppy Claire Edwards.jpg',
        name:'Poppy Claire Edward',
        designation:'Loyalty Program Manager'
    },
    {
        srcImage:'assets/Aboutus/AAC - Lucy Ellen.jpg',
        name:'Lucy Ellen',
        designation:'Data Scientist'
    },
    {
        srcImage:'assets/Aboutus/AAC - Alfie Daniel Baker.jpg',
        name:'Alfie Daniel Baker',
        designation:'Financial Analyst'
    },
    {
        srcImage:'assets/Aboutus/AAC - William John Wright.jpg',
        name:'William John Wright',
        designation:'Customer Training Manager'
    },
]