import { Box, Typography } from '@mui/material'
import React from 'react'
import './verify.scss';

export const Verification = () => {
  return (
    <Box className='verify-main'>
        <Box className='verify-image'>
            <img src='assets/Account/verify.svg' />
        </Box>
        <Box>
            <Typography className='verfiy-title1'>Let’s Get You Verified</Typography>
            <Typography className='verfiy-title2'>A verification Link has been  sent to your email account. Please click on that link for further verification to proceed.</Typography>
        </Box>
        <Box>
            <Typography className='resend-link'>If you didn’t get the verification email click on <span className='link'>Resend link</span></Typography>
        </Box>
    </Box>
  )
}
