import React from 'react'
import { Box, Container, Grid} from '@mui/material'
import './apple-cart2.scss'
import { cartData2 } from './cart2-data'

import { ExploreSection } from './ExploreSection'

export const AppleCart2 = () => {
  return (
    <Container maxWidth="xl" sx={{marginTop: '120px'}}>
        <Box>
            <div className='cart2-ellipse'>
                <img src={'assets/Home/Ellipse 22.svg'} />
            </div>
        </Box>
        <Box className="cart2-card">
            <div className='cart2-heading'>
                <span className='heading-typo'>Why Choose Air Applecart ?</span>
            </div>
            <Grid container className='cart2-grid-section'>
                <Grid xm={12} sm={12} mid={6} lg={6}>
                    <Box className="cart2-grid-left">
                        <div className='left' style={{width: '80%'}}>
                            {
                                cartData2?.map((data, index)=> (
                                    <section key={data?.id} style={{marginBottom: '20px'}}>
                                        <span className='heading'>{data?.heading}</span>
                                        <p className='paragraph'>{data?.desc}</p>
                                    </section>
                                ))
                            }    
                        </div>
                    </Box>
                </Grid>
                
                <Grid xm={12} sm={12} md={12} lg={6} className='cart2-grid-right-main'>
                    <Box className='cart2-grid-right'>
                       <img src='assets/Home/applecart2.svg'/>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <ExploreSection 
            paragraph={'Join the revolution in customer relationship management'}
            title={'Explore Air Applecart Today and Take your Business to New Heights'}
            description={'Elevate engagement, enhance efficiency, and embrace success with Air Applecart.'}
         />
    </Container>
  )
}
