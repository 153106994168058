import { Box, Button, ClickAwayListener,  Collapse, } from "@mui/material";
import React, { useState } from "react";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./HomeLayout.scss";
import { BASE_FE_APP_URL } from "../../../config";

const MobileHeader = ({ path }) => {
  const [active, setActive] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };
  const handleClose = () => {
    setShowSubMenu(false);
    setActive(false);
  };
  const location = useLocation()?.pathname?.split("/")?.filter(n => n);
  return (
    <ClickAwayListener onClickAway={handleClose}>
    <div>
      <Box
        className={`burger ${active ? "active" : ""}`}
        onClick={() => {
          !active ? setActive(true) : handleClose()
          }}
      >
        <Box className="strip burger-strip-2">
          <Box></Box>
          <Box></Box>
          <Box></Box>
        </Box>
      </Box>
      <Box
        className="mobile-menu-list"
        style={{
          top: active ? "100px" : "-600px",
          left:'-5px',
          opacity: active ? "1" : "0",
          zIndex: 200
        }}
      >
        <ul className="navbar-list">
          {path?.map((e) => (
              e?.list?.length > 0 ? (
                  <div  key={e?.name} style={{width:'100%'}}>
                    <NavLink onClick={handleSubMenu} style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between', width:'100%' }} className={({ isActive }) => { return (isActive && showSubMenu) || location[0] === 'product' ? "nav-link active" : "nav-link" }} to={e?.route}>
                      {e?.name} &nbsp; <svg style={{ rotate: showSubMenu ? '180deg' : '0deg',marginRight:'20px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M16.6004 7.45837L11.1671 12.8917C10.5254 13.5334 9.47539 13.5334 8.83372 12.8917L3.40039 7.45837" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      </svg></NavLink>
                    <Collapse  in={showSubMenu} mountOnEnter unmountOnExit>
                      <ul className="sub-menu-mobile">
                        {
                          e?.list?.map((data, index) => (
                            <li key={data?.subRoute}><NavLink className={'nav-link'} onClick={handleClose} to={data?.subRoute}>{data?.subName}</NavLink></li>
                          ))
                        }
                      </ul>
                    </Collapse>
                  </div>
              ) : (
                <React.Fragment key={e?.name}>
                  <NavLink className={'nav-link'} onClick={handleClose} to={e?.route}>{e?.name}</NavLink>
                </React.Fragment>
              )
          ))}
        </ul>
        <Box className="navbar-btn-parent">
        <Link onClick={handleClose} to={`${BASE_FE_APP_URL}/login`}>
          <Button onClick={handleClose} className="signin">
            Sign In
          </Button>
        </Link>
          &nbsp; &nbsp;
          {/* <Link onClick={handleClose} to="/partner-with-us">
            <Button className="plan-with-us">
              Partner with us
            </Button>
          </Link> */}
          &nbsp; &nbsp;
          <Link onClick={handleClose} to={`${BASE_FE_APP_URL}/sign-up`}>
            <Button onClick={handleClose} className="signup">
              Sign up
            </Button>
          </Link>
        </Box>
      </Box>
    </div>
    </ClickAwayListener>
  );
};

export default MobileHeader;
