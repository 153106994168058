import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import './footer.scss'
import { Link } from 'react-router-dom';
import { AIR_APPLE_CART_NAME, AIR_APPLE_CART__OLD_NAME } from '../../../config';

export const Footer = () => {
  const social_icons = [
    {
      id: 1,
      icon: '/twitter.svg',
      link: 'https://twitter.com/airapplecart_uk'
    },
    {
      id: 2,
      icon: '/instagram.svg',
      link: 'https://www.instagram.com/airapplecartofficial'
    },
    {
      id: 3,
      icon: '/facebook.svg',
      link: 'https://www.facebook.com/airapplecartofficial'
    },
    {
      id: 4,
      icon: '/linkedin.svg',
      link: 'https://www.linkedin.com/company/air-apple-cart'
    },
    {
      id: 5,
      icon: '/tiktok.svg',
      link: 'https://www.tiktok.com/@airapplecart_'
    },
    {
      id: 6,
      icon: '/pintrest.svg',
      link: 'https://pin.it/3qjEtwbam'
    },
    {
      id: 7,
      icon: '/youtube.svg',
      link: 'https://www.youtube.com/channel/UCYds33CZ8Cq7_prFTI4tgJg'
    },
  ];
  return (
    <div style={{ width: '100%', background: '#F9FAFB' }}>
      <Container maxWidth="xl" sx={{ padding: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <div>
            <img src='assets/Home/Logo.png' alt='Logo' />
          </div>
        </Box>
        <br />
        <Box className="footer-links" sx={{ display: 'flex', justifyContent: 'center' }}>
          <span className='footer-nav'><Link style={{ textDecoration: 'none', color: '#1F2937' }} to="/cookie-policy">Cookie Policy</Link> </span>
          <span className='footer-nav'><Link style={{ textDecoration: 'none', color: '#1F2937' }} to="/privacy-policy">Privacy Policy</Link></span>
          {/* <span className='footer-nav'><Link style={{ textDecoration: 'none', color: '#1F2937' }} to="/data-policy">Data Policy</Link></span> */}
          <span className='footer-nav'><Link style={{ textDecoration: 'none', color: '#1F2937' }} to="/terms-and-conditions">Terms & Conditions</Link></span>
          <span className='footer-nav'><Link style={{ textDecoration: 'none', color: '#1F2937' }} to="/careers">Careers</Link></span>
          <span className='footer-nav'><Link style={{ textDecoration: 'none', color: '#1F2937' }} to="/contact-us">Contact Us</Link></span>
          <span className='footer-nav'><Link style={{ textDecoration: 'none', color: '#1F2937' }} to="/about-us">About Us</Link></span>
        </Box>
        <br />
        <div style={{ marginBottom: '20px' }}>
          <Divider variant="inset" sx={{ position: 'relative', left: '-70px', width: '100%' }} />
        </div>
        <div className='below-footer-icons'>
          <Typography className='right-reserved'>© 2024 {AIR_APPLE_CART__OLD_NAME}. All rights reserved.</Typography>
          <br />
          <Box>
            {
              social_icons?.map((data) => (
               <a style={{textDecoration: 'none'}} href={data?.link} target='_blank'> <span className='footer-icons' key={data?.id}><img src={data?.icon} /></span> </a>
              ))
            }
          </Box>
        </div>
      </Container>
    </div>
  )
}
