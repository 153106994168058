import { Box, Button, ClickAwayListener, Container, Slide } from "@mui/material";
import React, { useState } from "react";
// import Logo from "../assests/home-page/home/logo-home.svg";
import "./HomeLayout.scss";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { Link, NavLink, useLocation } from "react-router-dom";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import MobileHeader from "./MobileHeader";
import { BASE_FE_APP_URL } from "../../../config";

export const Navbar = () => {
  const path = [
    {
      route: "/",
      name: "Home",
      isSubMenu: false,
      list: null,
    },
    {
      route: "/about-us",
      name: "About Us",
      isSubMenu: false,
      list: null,
    },
    {
      route: "#",
      name: "Products",
      isSubMenu: false,
      list: [
        { subRoute: '/product/air-marketer', subName: 'Air Marketer' },
        { subRoute: '/product/air-sales', subName: 'Air Sales' },
        { subRoute: '/product/air-service', subName: 'Air Services' },
        { subRoute: '/product/air-operation', subName: 'Air Operations' },
        { subRoute: '/product/air-loyalty-program', subName: 'Loyalty Program' },
        { subRoute: '/product/air-call-center', subName: 'Call Center' },
        { subRoute: '/product/air-calander', subName: 'Calendar Piper' },
        { subRoute: '/product/air-fiat-sign', subName: 'Fiat Sign' },
       
        // { subRoute: '/product/air-grammer-tok', subName: 'Grammer Tok' },
      ],
    },
    {
      route: "/pricing",
      name: "Pricing",
      isSubMenu: true,
      list: [],
    },
    {
      route: "/careers",
      name: "Careers",
      isSubMenu: false,
      list: null,
    },
    {
      route: "/contact-us",
      name: "Contact Us",
      isSubMenu: false,
      list: null,
    },
  ];
  const [active, setActive] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleMouseEnter = () => {
    if (isMobile) return;
    setShowSubMenu(!showSubMenu);
  };

  const { width } = useWindowDimensions();
  const isMobile = width < 1050;
  const location = useLocation()?.pathname?.split("/")?.filter(n => n);
  return (
    <Box sx={{ position: 'relative', zIndex: '10' }} component={"nav"} className="navbar-parent">
      <Container maxWidth="xl">
        <Box sx={{ position: 'relative', zIndex: '10' }} className="navbar-box">
          <Box className="image-box">
            <Link to="/">
              <img src={'assets/Home/Logo.png'} alt="logo" width={153} height={38} loading="lazy" />
            </Link>
          </Box>
          {!isMobile && (
            <>
              <Box sx={{ position: 'relative', zIndex: '10' }} className="navbar-list-parent">
                <ul className="navbar-list">
                  {path?.map((e) => (
                    e?.list?.length > 0 ? (
                      <ClickAwayListener key={e?.name} onClickAway={() => setShowSubMenu(false)}>
                        <div style={{ marginLeft: '30px' }}>
                          <NavLink onClick={handleMouseEnter} style={{ display: 'flex', alignItems: 'center' }} className={({ isActive }) => {return (isActive && showSubMenu) || location[0] === 'product' ? "nav-link active" : "nav-link" }} to={e?.route}>
                            {e?.name} &nbsp; <svg style={{ rotate: showSubMenu ? '180deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <path d="M16.6004 7.45837L11.1671 12.8917C10.5254 13.5334 9.47539 13.5334 8.83372 12.8917L3.40039 7.45837" stroke={active ? "#38CAB5" : "#6B7280"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg></NavLink>
                          <Slide direction="down" in={showSubMenu} mountOnEnter unmountOnExit>
                            <ul className="sub-menu">
                              {
                                e?.list?.map((data) => (
                                  <li key={data?.subRoute}><NavLink className={'nav-link'} onClick={handleMouseEnter} to={data?.subRoute}>{data?.subName}</NavLink></li>
                                ))
                              }
                            </ul>
                          </Slide>
                        </div>
                      </ClickAwayListener>
                    ) : (
                      <React.Fragment key={e?.name}>
                        <NavLink className={'nav-link'} to={e?.route}>{e?.name}</NavLink>
                      </React.Fragment>
                    )
                  ))}
                </ul>
              </Box>
              <Box sx={{ position: 'relative', zIndex: '10' }} className="navbar-btn-parent">
              <Link to={`${BASE_FE_APP_URL}/login`}>
                <Button className="signin">
                  Sign In
                </Button>
              </Link>
                &nbsp; &nbsp;
                {/* <Link to="/partner-with-us">
                  <Button className="plan-with-us">
                    Partner with us
                  </Button>
                </Link> */}
                &nbsp; &nbsp;
                <Link to={`${BASE_FE_APP_URL}/sign-up`}>
                  <Button className="signup">
                    Sign up
                  </Button>
                </Link>
              </Box>
            </>
          )}
          {isMobile && <MobileHeader path={path} />}
        </Box>
      </Container >
    </Box >
  );
};
