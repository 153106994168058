import React from 'react';
import { Box, Grid } from '@mui/material';
import './accounts.scss';
import { Header } from './Header';

export const Accounts = ({children}) => {
  return (
    <Box className="account-box">
        <Header />
        <Grid container className='account-container'>
            <Grid sm={12} md={6} lg={6} xl={6} className='account-left-side'>
                {children}
            </Grid>
            
            <Grid className='account-right-side' sm={12} md={6} lg={6} xl={6}>
                <Box className='right-side-image'>
                    <img src='assets/Account/accounts.svg' alt='Accounts Image'/>
                </Box>
            </Grid>
        </Grid>
    </Box>
  )
}
