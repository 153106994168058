import { Box } from '@mui/material'
import React from 'react'
export const PolicyHPL = ({data}) => {
    return (
        <>
            {
                data.map(item => <Box key={item.id} sx={{ mt: '24px', mb: '16px' }}>
                    <Box sx={{ mb: '14px', fontSize: '25px', fontWeight: '600', color: '#1F2937' }}>
                        {item.id}. {item.heading}
                    </Box>
                    {item.description && <Box sx={{ mb: '25px', fontSize: '18px', fontWeight: '500', color: '#6B7280' }}>
                        {item.description} {item.greenDescription && <span style={{ color: '#38CAB5' }}>{item.greenDescription}</span>} {item.lastDesc && item.lastDesc}
                    </Box>}
                    {item.list && <ul style={{paddingLeft: '20px'}}>
                        {
                            item.list.map((list, index) => <li key={index} className='policy-list'><Box sx={{ mb: '16px', fontSize: '18px', fontWeight: '400', color: '#6B7280' }}>{list.heading && <span style={{ fontWeight: '600', color: '#1F2937', }}>{list.heading}: </span>} {list.detail}</Box></li>)
                        }
                    </ul>}
                </Box>)
            }

        </>
    )
}
