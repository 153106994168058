import React from 'react'
import { Accounts } from '../../Common/Accounts/Accounts'
import { SignupForm } from './signup-form'

export const Signup = () => {
  return (
        <Accounts>
            <SignupForm />
        </Accounts>
  )
}
