import React from 'react'
import { Hero } from '../../Common/Products/hero-section/Hero'
import { PricingCards } from './pricing-cards';

export const Pricing = () => {
    const hero = [
        {
            title: 'Pricing Plans of Air Applecart Products',
            description: 'Verify your employees through Identity Gram & Get 10% discount'
        }
    ];
  return (
    <React.Fragment>
        <Hero hero={hero} page={'pricing-main'} />
        <PricingCards />
    </React.Fragment>
  )
}
