export const hero = [
    {
        title: 'Air Sales',
        section: 'Elevate Your Sales Game with Precision and Impact',
        description: 'Welcome to Air Sales, the cutting-edge sales solution brought to you by Air Applecart. Just like the Sales Hub in HubSpot, Air Sales is designed to empower your sales team, streamline processes, and drive remarkable growth. With an array of powerful tools and features, Air Sales equips you to manage leads, close deals, and foster lasting customer relationships.'
    }
];

export const features = [
    {
        id: 1,
        title: 'Lead Management',
        description: 'Effortlessly manage and track your leads from initial contact to closing the deal. Stay organisedwith a comprehensive view of all interactions and communication.',
        img: '/assets/Products/status-up.svg',
        icon_color: '#ECFFFC',
    },
    {
        id: 2,
        title: 'Sales Pipeline Management',
        description: 'visualise and optimise your sales pipeline for maximum efficiency. Move leads through stages, set priorities, and ensure no opportunity is overlooked.',
        img: '/assets/Products/announcement-02.svg',
        icon_color: '#F2F9FC'
    },
    {
        id: 3,
        title: 'Automation',
        description: 'Automate repetitive tasks and follow-ups to save time and ensure consistent communication with leads and clients.',
        img: '/assets/Products/chart.svg',
        icon_color: '#FFF4F6'
    },
    {
        id: 4,
        title: 'Analytics and Reporting',
        description: 'Gain insights into your sales performance through detailed analytics and reporting. Identify trends, track conversions, and make data-driven decisions.',
        img: '/assets/Products/social-media 1.png',
        icon_color: '#FBF9FF'
    },
    {
        id: 5,
        title: 'Contact Management',
        description: 'Keep a centralised database of all your contacts and easily access their information, interactions, and history.',
        img: '/assets/Products/magnetism 1.svg',
        icon_color: '#FFF6F1'
    },
    {
        id: 6,
        title: 'Email Tracking and Templates',
        description: 'Monitor email engagement with real-time notifications. Use Customisable email templates to create consistent and impactful communication.',
        img: '/assets/Products/personalisation 1.svg',
        icon_color: '#FEFEEE'
    },
];

export const benefits = [
    {
        description: `Air Sales is your ultimate partner for sales success, just like the Sales Hub in HubSpot. We're dedicated to helping you elevate your sales strategies and achieve remarkable growth. Join the league of businesses that have experienced exponential sales success with Air Sales by Air Applecart.`,
        image: '/assets/Products/Air-Sales-benefit.png',
        benefit: [
            {
                id: 1,
                title: 'Efficient Lead Management',
                description: `Air Sales simplifies lead tracking, ensuring no potential opportunity falls through the cracks. Prioritise leads, manage interactions, and boost conversion rates.`,
                img: '/assets/Products/status-up.svg',
            },
            {
                id: 2,
                title: 'optimised Sales Process',
                description: `visualise your sales pipeline to identify bottlenecks and optimise each stage for better efficiency. Enhance your chances of closing deals effectively.`,
                img: '/assets/Products/decision-making 1.svg',
            },
            {
                id: 3,
                title: 'Streamlined Communication',
                description: `Automate follow-ups and nurture leads with personalised communication. Build stronger relationships with prospects and clients.`,
                img: '/assets/Products/people.svg',
            },
            {
                id: 4,
                title: 'Data-Driven Decisions',
                description: `Leverage analytics to track sales performance and identify areas for improvement. Make informed decisions that lead to increased sales.`,
                img: '/assets/Products/data.svg',
            },
        ]
    }
];

export const joinUs = {
    title: 'Ready to Boost Your Sales Performance?',
    description: `Explore Air Sales today and discover how our innovative features and dedicated support can transform your sales efforts. Whether you're a startup or an established enterprise, Air Sales adapts to your needs and empowers you to thrive in today's competitive market. Begin your journey with us and revolutionise your sales strategies with Air Applecart.`,
    img: '/assets/Products/air-sales-join.svg',
}