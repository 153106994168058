// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-heading {
  color: #1F2937;
  font-size: 48px;
  font-family: "Plus Jakarta Sans";
  font-weight: 600;
  line-height: 57.6px;
  word-wrap: break-word;
}

.acc-title {
  color: #1F2937 !important;
  font-size: 16px !important;
  font-family: Plus Jakarta Sans !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  word-wrap: break-word !important;
}

.acc-desc {
  color: #1F2937 !important;
  font-size: 16px !important;
  font-family: "Plus Jakarta Sans" !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  word-wrap: break-word !important;
}

@media screen and (max-width: 600px) {
  .faq-heading {
    font-size: 30px !important;
  }
  .accordian-body {
    padding: 0px !important;
    padding-top: 20px !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 430px) {
  .faq-heading {
    font-size: 25px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Home/Faq/faq.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,gCAAA;EACA,gBAAA;EACA,mBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,0BAAA;EACA,yCAAA;EACA,2BAAA;EACA,4BAAA;EACA,gCAAA;AACJ;;AACA;EACI,yBAAA;EACA,0BAAA;EACA,2CAAA;EACA,2BAAA;EACA,4BAAA;EACA,gCAAA;AAEJ;;AACA;EACI;IACI,0BAAA;EAEN;EAAE;IACI,uBAAA;IACA,4BAAA;IACA,sBAAA;EAEN;AACF;AACA;EACI;IACI,0BAAA;EACN;AACF","sourcesContent":[".faq-heading{\n    color: #1F2937;\n    font-size: 48px;\n    font-family: 'Plus Jakarta Sans';\n    font-weight: 600;\n    line-height: 57.60px;\n    word-wrap: break-word\n}\n\n.acc-title{\n    color: #1F2937 !important;\n    font-size: 16px !important;\n    font-family: Plus Jakarta Sans !important;\n    font-weight: 400 !important;\n    line-height: 24px !important;\n    word-wrap: break-word !important;\n}\n.acc-desc{\n    color: #1F2937 !important;\n    font-size: 16px !important;\n    font-family: 'Plus Jakarta Sans' !important;\n    font-weight: 400 !important;\n    line-height: 24px !important;\n    word-wrap: break-word !important;\n}\n\n@media screen and (max-width: 600px) {\n    .faq-heading{\n        font-size: 30px !important;\n    }\n    .accordian-body{\n        padding: 0px !important;\n        padding-top: 20px !important;\n        width: 100% !important;\n    }\n}\n\n@media screen and (max-width: 430px) {\n    .faq-heading{\n        font-size: 25px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
