import { Box, Card, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import './air-services.scss';
import { Link } from 'react-router-dom';

export const AirServices = () => {
  
  const cardData = [
    {
      id: '1',
      title: 'Air Marketer',
      desc: 'Air Marketer is your comprehensive solution for executing successful marketing campaigns and achieving your business objectives.',
      link: '/product/air-marketer',
    },
    {
      id: '2',
      title: 'Air Sales',
      desc: `Air Sales is your ultimate partner for sales success.We're dedicated to helping you elevate your sales strategies and achieve remarkable growth`,
      link: '/product/air-sales',
    },
    {
      id: '3',
      title: 'Air Services',
      desc: `Air Services is your gateway to delivering exceptional client services.Our commitment is to empower your team to provide top-tier services and ensure utmost client satisfaction.`,
      link: '/product/air-service',
    },
    {
      id: '4',
      title: 'Air Operations',
      desc: `Air Operations is your trusted partner in revolutionising operational excellence. Our commitment lies in enhancing your organisation's efficiency, productivity, and collaboration.`,
      link: '/product/air-operation',
    },
    {
      id: '5',
      title: 'Loyalty Program',
      desc: `Enhance customer retention and boost engagement. Seamlessly manage loyalty points, vouchers, and gift cards while offering personalized rewards to your customers`,
      link: '/product/air-loyalty-program',
    },

    {
      id: '6',
      title: 'Call Center',
      desc: `Optimize your customer interactions. Manage inbound and outbound calls efficiently with features like IVRs, call recording, automatic note-taking, and conference calls. Schedule calls effortlessly and enhance customer service with robust call management tools.`,
      link: '/product/air-call-center',
    },
    {
      id: '7',
      title: 'Calendar Piper',
      desc: `Calendar Piper is an innovative addition to Air Applecart that empowers you to effortlessly manage your time, appointments, and engagements.`,
      link: '/product/air-calander',
    },

    {
      id: '8',
      title: 'Fiat Sign',
      desc: `Fiat Sign is the contract management solution your business needs to navigate the intricacies of contract management seamlessly.`,
      link: '/product/air-fiat-sign',
    },
    // {
    //   id: '9',
    //   title: 'Stenogofer',
    //   desc: `Elevate your communication and collaboration game with Stenogofer, seamlessly integrated into Air Applecart.`,
    //   link: '/product/air-stenogofer',
    // },
    // {
    //   id: '10',
    //   title: 'MLM',
    //   desc: `Experience the evolution of marketing with Air Applecart's MLM. Our MLM module equips you with the capabilities to build and manage a thriving network of distributors.`,
    //   link: '/product/air-mlm',
    // },
    // {
    //   id: '9',
    //   title: 'Grammer Tok',
    //   desc: `With Grammar Tok you can master the art of effective writing. Whether you're a student, professional, or content creator, It helps you achieve your communication goals with ease.`,
    //   link: '/product/air-grammer-tok',
    // },
  ]

  return (
    <Container maxWidth="xl" sx={{marginTop: '100px'}}>
        <Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <div style={{color: '#6B7280', fontSize: '16px', fontFamily: 'Plus Jakarta Sans', fontWeight: '600px', lineHeight: '24px', letterSpacing: '1.25px', wordWrap: 'break-word'}}>Air Applecart PRODUCTS</div>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
              <strong className='top-secondary-title' style={{color: '#1F2937', fontSize: '48px', fontFamily: 'Plus Jakarta Sans', fontWeight: '600px', wordWrap: 'break-word'}}>All SaaS, no bloat.</strong>
            </Box>
            <br/>
            <br/>
            <br/>
            <Grid container>
              {
                cardData?.map((val, index)=>(
                  <Grid xs={12} sm={12} md={6} lg={6} xl={4} sx={{display: 'flex', justifyContent:'space-around'}}>
                  <Card variant='outlined' key={index} className='service-card'>
                      <div className='card-top'>
                        <div style={{background: '#fafafa', padding: '7px 10px', borderRadius: '12px', border: '1px solid lightgray'}}>
                          <img src='assets/Home/Wrench.svg' alt='Icons'/>
                        </div>
                        <div style={{padding: '7px 10px'}}>
                          <Link to={val?.link}>
                            <img src='assets/Home/far-arrow.svg' alt='Icons'/>
                          </Link>
                        </div>
                      </div>
                      <br/>
                      <div className='card-title'>
                        <span>{val?.title}</span>
                      </div>
                      <div className='card-description'>
                        <p>{val?.desc}</p>
                      </div>
                  </Card>
                </Grid>
                ))
              }
            </Grid>
        </Box>
    </Container>
  )
}
