// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading-top {
  color: #1D2026;
  font-size: 40px;
  font-family: Plus Jakarta Sans;
  font-weight: 600;
  line-height: 48px;
  word-wrap: break-word;
}

.heading-typo {
  color: #6B7280 !important;
  font-size: 16px !important;
  font-family: "Plus Jakarta Sans" !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  word-wrap: break-word !important;
}

@media screen and (max-width: 750px) {
  .apple-cart1-img {
    position: unset !important;
    display: block !important;
  }
}
@media screen and (max-width: 550px) {
  .heading-top {
    font-size: 30px;
  }
}
@media screen and (max-width: 400px) {
  .heading-top {
    font-size: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Home/apple-cart1/apple-cart1.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACJ,eAAA;EACA,8BAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AACA;;AACA;EACI,yBAAA;EACA,0BAAA;EACA,2CAAA;EACA,2BAAA;EACA,4BAAA;EACA,gCAAA;AAEJ;;AACA;EACI;IACI,0BAAA;IACA,yBAAA;EAEN;AACF;AACA;EACI;IACI,eAAA;EACN;AACF;AAEA;EACI;IACI,eAAA;EAAN;AACF","sourcesContent":[".heading-top{\n    color: #1D2026;\nfont-size: 40px;\nfont-family: Plus Jakarta Sans;\nfont-weight: 600;\nline-height: 48px;\nword-wrap: break-word\n}\n.heading-typo{\n    color: #6B7280 !important;\n    font-size: 16px !important;\n    font-family: 'Plus Jakarta Sans' !important;\n    font-weight: 500 !important;\n    line-height: 24px !important;\n    word-wrap: break-word !important;\n}\n\n@media screen and (max-width: 750px) {\n    .apple-cart1-img{\n        position: unset !important;\n        display: block !important;\n    }\n}\n\n@media screen and (max-width: 550px) {\n    .heading-top{\n        font-size: 30px;\n    }\n}\n\n@media screen and (max-width: 400px) {\n    .heading-top{\n        font-size: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
