import { Box, Container } from "@mui/material";
import "./careers.scss";
export const Careers = () => {
  return (
    <div className="careers">
      <div className="Hero-Section">
        {/* <div className={`hero-section-bg`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="515" height="844" viewBox="0 0 515 844" fill="none">
                    <g filter="url(#filter0_f_1816_10190)">
                        <circle cx="429" cy="415" r="179" fill="url(#paint0_linear_1816_10190)" fill-opacity="0.3" />
                    </g>
                    <defs>
                        <filter id="filter0_f_1816_10190" x="0" y="-14" width="858" height="858" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_1816_10190" />
                        </filter>
                        <linearGradient id="paint0_linear_1816_10190" x1="429" y1="236" x2="429" y2="594" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#2AEDD1" />
                            <stop offset="0.557292" stop-color="#85E8F5" />
                            <stop offset="1" stop-color="#FF71F9" />
                        </linearGradient>
                    </defs>
                </svg>
            </div> */}
        <Container sx={{ position: "relative" }}>
          {/* <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                    <Box>
                        <img className='arrow-image-1' src='assets/Home/abstract.png' alt='Arrow' />
                        <img className='stars-icons' src='assets/Home/Stars.svg' alt='Stars Icons' />
                    </Box>
                    <Box className="hero-section-content">
                        <div className='hero-content-size' style={{ width: '100%' }}>
                            <div className='hero-top'>Career Openings</div>
                            <br />
                        </div>
                    </Box>
                </Box> */}
          {/* <Box sx={{ mb: '40px', mt: { md: '40px', xs: 0 } }} className="job-openings">
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: 'center',
                            flexDirection: "column",
                            mb: '30px'
                        }}
                    >
                        <Box
                            sx={{
                                fontSize: "18px",
                                lineHeight: "22px",
                                color: "#38CAB5",
                                fontWeight: 500,
                                marginTop: "20px",
                                textAlign: 'center'
                            }}
                        >
                            Come join us
                        </Box>
                        <p
                            style={{
                                marginBottom: "20px",
                                fontSize: "15px",
                                fontFamily: `"Plus Jakarta Sans"`,
                                lineHeight: "24px",
                                textAlign: "center",
                                color: "#111827",
                                fontWeight: 400,
                                maxWidth: "674px"
                            }}
                        >
                            We’re always looking for creative, talented self-starters to join the <span style={{ color: "#38CAB5", fontWeight: 600, }}> Air Applecart </span> family. Check out our open roles below and fill out an application.

                        </p>
                    </Box> */}
          <Box style={{ overflow: "hidden" }} className="job-openings">
            <iframe
              // src="https://jobs.orcaloholding.co.uk/jobs/companies/OLblg9eNjG2ALrk8vRB3DadPpE"
              src="https://recruiting-stg.personnellibrary.co.uk//job-boards?boardName=airAppleExternalBoard-1716226310381"
              width="100%"
              height="100%"
              allowfullscreen="false"
              // scrolling="no"
              title="Jobs Widget"
              style={{ border: "none", minHeight: "950px" }}
            ></iframe>
          </Box>
          {/* </Box> */}
        </Container>
      </div>
    </div>
  );
};
