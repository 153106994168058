export const hero = [
    {
        title: 'Loyalty Program',
        section: 'Enhance Customer Engagement and Retention with a Loyalty Program',
        description: `Welcome to Loyalty Program, your key to fostering lasting relationships and rewarding customer loyalty. Loyalty Program is crafted to delight your customers and keep them coming back for more. With its customisable rewards system and insightful analytics, Loyalty Program enables you to create engaging loyalty programs, track customer engagement, and drive repeat business.`
    }
];

export const features = [
    {
        id: 1,
        title: 'Reward and vouchers',
        description: 'Treat your customers to special rewards and irresistible vouchers, keeping them smiling and coming back for more.',
        img: '/assets/Products/reward-and-voucher.svg',
        icon_color: '#ECFFFC',
    },
    {
        id: 2,
        title: 'Gift cards',
        description: 'Spread joy and create lasting memories with our delightful gift card options, perfect for every occasion and recipient.',
        img: '/assets/Products/gift.svg',
        icon_color: '#F2F9FC'
    },
    {
        id: 4,
        title: 'Prepaid',
        description: 'Simplify transactions and empower your customers with prepaid balances, ensuring smooth shopping experiences at every turn.',
        img: '/assets/Products/prepaid.svg',
        icon_color: '#FFF4F6'
    },
];

export const benefits = [
    {
        description: `Gain a competitive edge and foster enduring customer relationships with Loyalty Program. Our innovative loyalty solution is designed to elevate your business by prioritising customer satisfaction and engagement. With Loyalty Program, you can seamlessly reward loyal patrons, drive repeat purchases, and unlock valuable insights into consumer behavior.`,
        image: '/assets/Products/benefits-call-center.svg',
        benefit: [
            {
                id: 1,
                title: 'Customer Retention',
                description: `Reward loyalty, drive repeat business, and build lasting relationships.`,
                img: '/assets/Products/customer-retention.svg',
            },
            {
                id: 2,
                title: 'Customer Engagement',
                description: `Personalised rewards keep customers actively involved and delighted with your brand.`,
                img: '/assets/Products/people.svg',
            },
            {
                id: 3,
                title: 'Valuable Insights',
                description: `Dive into data to refine your offerings and boost effectiveness, ensuring every interaction counts.`,
                img: '/assets/Products/analytical-product.svg',
            },
            {
                id: 4,
                title: 'Revenue Growth',
                description: `Boosted customer loyalty drives sales and profits, maximising each customer's lifetime value and overall revenue growth.`,
                img: '/assets/Products/status-up.svg',
            },
        ]
    }
];

export const joinUs = {
    title: 'Ready to experience the perks of our  Loyalty Program',
    description: `Join the  Loyalty Program today and unlock a world of exclusive benefits, rewards, and personalised offers tailored just for you. Start earning points with every purchase and enjoy priority access to special promotions and events. Don't miss out on the perks – join us now and elevate your experience with us!`,
    img: '/assets/Products/air-loyalty-join.svg',
}