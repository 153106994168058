import { Box, Container } from '@mui/material'
import React from 'react';
import './hero-section.scss';
import { useLocation } from 'react-router-dom';

export const Hero = ({ hero, page, idHref,products }) => {
  const location = useLocation()?.pathname;
  return (
    <div className="Hero-Section2">
      <div className={`hero-section-bg`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="515" height="844" viewBox="0 0 515 844" fill="none">
          <g filter="url(#filter0_f_1816_10190)">
            <circle cx="429" cy="415" r="179" fill="url(#paint0_linear_1816_10190)" fill-opacity="0.3" />
          </g>
          <defs>
            <filter id="filter0_f_1816_10190" x="0" y="-14" width="858" height="858" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_1816_10190" />
            </filter>
            <linearGradient id="paint0_linear_1816_10190" x1="429" y1="236" x2="429" y2="594" gradientUnits="userSpaceOnUse">
              <stop stop-color="#2AEDD1" />
              <stop offset="0.557292" stop-color="#85E8F5" />
              <stop offset="1" stop-color="#FF71F9" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <Container sx={{ display: 'flex', justifyContent: 'center', position: "relative", zIndex: '9' }}>
        <Box>
        {!products && <img className='arrow-image3' src='assets/Home/abstract.png' alt='Arrow' />}
        {!products &&  <img className='stars-icons3' src='assets/Home/Stars.svg' alt='Stars Icons' />}
              
        </Box>
        <Box className="hero-section-content2">
          <div className='hero-content-size2'>
          <div style={{position:'relative',whiteSpace:products ? 'nowrap' : 'normal'}} className='hero-top2'>
           {products && <img className='arrow-image2' src='assets/Home/abstract.png' alt='Arrow' />}
            {products && <img className='stars-icons2' src='assets/Home/Stars.svg' style={{left: hero[0]?.title.length<14 ? '77%' : '100%'}} alt='Stars Icons' />}
              
              {hero[0]?.title}
            </div>
            {
              !page && (
                <div className='hero-middle2'>{hero[0]?.section}</div>
              )
            }
            <div>
              <p className='hero-paragraph2'>
                {page == 'pricing-main' && (
                  <img src='/assets/Pricing/ToggleBase.svg' style={{ paddingRight: '10px', position: 'relative', top: '3px' }} />
                )}
                {hero[0]?.description}</p>
            </div>
            <br />
            {page && (
              <div style={{ marginBottom: '20px' }} />
            )}
            {
              !page && (
                <>
                  <a href={`${location}#${idHref}`}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Box className="animate-downward-parent" sx={{ width: '41px', height: '83px', background: '#F3F4F6', borderRadius: 22, }}>
                        <svg className="animate-downward" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                          <path className="animate-path" fill-rule="evenodd" clip-rule="evenodd" d="M20.0002 28C20.0002 29.0609 19.5788 30.0783 18.8286 30.8284C18.0785 31.5786 17.061 32 16.0002 32C14.9393 32 13.9219 31.5786 13.1718 30.8284C12.4216 30.0783 12.0002 29.0609 12.0002 28C12.0002 26.9391 12.4216 25.9217 13.1718 25.1716C13.9219 24.4214 14.9393 24 16.0002 24C17.061 24 18.0785 24.4214 18.8286 25.1716C19.5788 25.9217 20.0002 26.9391 20.0002 28ZM23.5602 10.318C23.2789 10.0371 22.8977 9.87932 22.5002 9.87932C22.1027 9.87932 21.7214 10.0371 21.4402 10.318L17.5002 14.258V1.5C17.5002 1.10218 17.3421 0.720644 17.0608 0.43934C16.7795 0.158035 16.398 0 16.0002 0C15.6024 0 15.2208 0.158035 14.9395 0.43934C14.6582 0.720644 14.5002 1.10218 14.5002 1.5V14.258L10.5602 10.318C10.2758 10.053 9.89974 9.90879 9.51114 9.91565C9.12253 9.92251 8.75176 10.0799 8.47694 10.3548C8.20211 10.6296 8.04469 11.0004 8.03783 11.389C8.03097 11.7776 8.17522 12.1537 8.44018 12.438L14.9402 18.938L16.0002 20L17.0602 18.94L23.5602 12.44C23.6996 12.3007 23.8101 12.1353 23.8856 11.9532C23.961 11.7712 23.9999 11.5761 23.9999 11.379C23.9999 11.1819 23.961 10.9868 23.8856 10.8048C23.8101 10.6227 23.6996 10.4573 23.5602 10.318Z" fill="#1F2937" />
                        </svg>
                      </Box>
                    </div>
                  </a>
                </>
              )
            }
          </div>
        </Box>
      </Container>
    </div>
  )
}
