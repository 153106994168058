import React from 'react'
import { Accounts } from '../../Common/Accounts/Accounts'
import { SigninForm } from './signin-form'

export const Signin = () => {
  return (
    <Accounts>
        <SigninForm />
    </Accounts>
  )
}
