import React from 'react'
import { Verification } from '../../Features/verification/verification'
import { Header } from '../../Common/Accounts/Header'

export const VerificationMain = () => {
  return (
    <React.Fragment>
        <Header />
        <Verification />
    </React.Fragment>
  )
}
