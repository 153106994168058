// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.flex {
  display: flex;
}
.pointer {
  cursor: pointer;
}
.text-24-600 {
  font-size: 24px;
  font-weight: 600;
}
.flex-center {
  align-items: center;
  justify-centent: center;
}
.candy {
  color: #9ca3af;
  font-size: 14px;
  font-family: Plus Jakarta Sans;
  width: 34px;
  padding: 16px 10px;
  text-align: center;
  border: 1px solid #E5E7EB !important;
  border-radius: 7px;
}
.policy-list::marker {
  color: #6B7280;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,4CAA4C;EAC5C,mCAAmC;EACnC,kCAAkC;AACpC;AACA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,cAAc;EACd,eAAe;EACf,8BAA8B;EAC9B,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,oCAAoC;EACpC,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap\");\nbody {\n  margin: 0;\n  font-family: \"Plus Jakarta Sans\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\nhtml,body {\n  overflow-x: hidden;\n  scroll-behavior: smooth;\n}\n\n.flex {\n  display: flex;\n}\n.pointer {\n  cursor: pointer;\n}\n.text-24-600 {\n  font-size: 24px;\n  font-weight: 600;\n}\n.flex-center {\n  align-items: center;\n  justify-centent: center;\n}\n.candy {\n  color: #9ca3af;\n  font-size: 14px;\n  font-family: Plus Jakarta Sans;\n  width: 34px;\n  padding: 16px 10px;\n  text-align: center;\n  border: 1px solid #E5E7EB !important;\n  border-radius: 7px;\n}\n.policy-list::marker {\n  color: #6B7280;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
