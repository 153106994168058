import React from 'react'
import './apple-cart2.scss'
import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { BASE_FE_APP_URL } from '../../../../config';
export const ExploreSection = ({paragraph, title, description, aboutUs}) => {
  return (
    <Box sx={{marginTop: '70px', padding: '30px'}}>
            <Grid container>
                <Grid xm={12} md={6} lg={6}>
                    <div>
                        <img style={{width: '100%'}} src='assets/Aboutus/about-us-mission.svg'/>
                    </div>  
                </Grid>
                
                <Grid xm={12} md={6} lg={6}>
                    <Box className="customer-relationship">
                        {paragraph && <p className='paragraph'>{paragraph}</p>}
                        <div className='title'>{title}</div><br/>
                        <div className='description'>{description}</div><br/>
                        {!aboutUs && 
                        <Link to={`${BASE_FE_APP_URL}/sign-up`}>
                            <button style={{cursor: 'pointer'}}>Join Us Today</button>
                        </Link>}
                    </Box>
                </Grid>
            </Grid>
        </Box>
  )
}
