import React from 'react'
import { Accounts } from '../../Common/Accounts/Accounts'
import { ForgotForm } from './ForgotForm'

export const ForgotPass = () => {
  return (
    <Accounts>
        <ForgotForm />
    </Accounts>
  )
}
