import { Avatar, Box, Typography } from "@mui/material";

export const TeamList = (props) => {
  const { data } = props;
  return (
    <Box textAlign={'center'} marginBottom={3}>
    <Avatar sx={{ width: 80, height: 80, margin: 'auto' }} src={data?.srcImage} alt={data?.name} />
    <br />
    <Typography fontWeight={600} color="#1F2937">{data?.name}</Typography>
    <Typography fontWeight={500} color="#6B7280">{data?.designation}</Typography>
  </Box>
  );
};