import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

export const customers_data = [
  {
    id: "1",
    review: `“Every time we have a complex Google Sheets solution or a quick problem we need to solve, now our brains go to, Can't we just build this in Glide?”`,
    name: "Liz Elliot",
    // occupation: 'PGA of America',
    color: "#12CCE5",
    backgroundColor: "#e9fcff",
    image: (
      <Stack direction="row" spacing={2}>
        <Avatar alt="Remy Sharp" src={`liz.png`} />
      </Stack>
    ),
  },
  {
    id: "2",
    review: `“Our processes are always changing, and that's the beauty of Glide. It can change with us.”`,
    name: "Sarah Boltwala-Mesina",
    // occupation: 'Food2Soil',
    color: "#0FD452",
    backgroundColor: "#efffed",
    image: (
      <Stack direction="row" spacing={2}>
        <Avatar alt="Remy Sharp" src={`sara.png`} />
      </Stack>
    ),
  },
  {
    id: "3",
    review: `“Over a hundred of my colleagues now rely heavily on this project. The data is always up to date and presenting it in a simple, easy-to-navigate interface makes spotting issues much easier.”`,
    name: "Marc Walford",
    // occupation: 'ITV',
    color: "#887EF9",
    backgroundColor: "#efeeff",
    image: (
      <Stack direction="row" spacing={2}>
        <Avatar alt="Remy Sharp" src={`marc.png`} />
      </Stack>
    ),
  },
  {
    id: "4",
    review: `“Implementing Air Applecart transformed our customer engagement strategy. Streamlined our operations and significantly boosted customer satisfaction”`,
    name: "Jennifer Connely",
    // occupation: 'PGA of America',
    color: "#12CCE5",
    backgroundColor: "#e9fcff",
    image: (
      <Stack direction="row" spacing={2}>
        <Avatar alt="Remy Sharp" src={`sard.jpg`} />
      </Stack>
    ),
  },
  // {
  //     id: '5',
  //     review: `“Our processes are always changing, and that's the beauty of Glide. It can change with us.”`,
  //     name: 'John Snow',
  //     occupation: 'Food2Soil',
  //     color: '#0FD452',
  //     backgroundColor: '#efffed',
  //     image:  <Stack direction="row" spacing={2}>
  //                 <Avatar alt="Remy Sharp" src={`avatar.jpg`} />
  //             </Stack>
  //     ,
  // },
];
