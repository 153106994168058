import React from "react";
import { PolicyFeature } from "../../Features/PolicyFeature/PolicyFeature";
import { AIR_APPLE_CART_NAME } from "../../../config";
export const cookiePolicy = [
  {
    id: 1,
    heading: "How We Use Cookies",
    description:
      "We collect information you provide when you use our platform, including:",
    list: [
      {
        heading: "Essential Cookies",
        detail:
          "These cookies are necessary for the proper functioning of our website and enable you to navigate and use its features.",
      },
      {
        heading: "Analytical and Performance Cookies",
        detail:
          "These cookies allow us to collect information about how you use our website, such as pages visited, and links clicked. They help us understand user preferences and improve site performance.",
      },
      {
        heading: "Targeting and Advertising Cookies",
        detail:
          "These cookies track your browsing habits to show you targeted advertisements based on your interests. They may also be used by third-party advertisers to display relevant content.  ",
      },
    ],
  },
  {
    id: 2,
    heading: "Types of Cookies We Use",
    list: [
      {
        heading: "Session Cookies",
        detail:
          "Session cookies are temporary and are deleted from your device once you close your browser.",
      },
      {
        heading: "Persistent Cookies",
        detail:
          "Persistent cookies remain on your device for a specified period or until you manually delete them.",
      },
    ],
  },
  {
    id: 3,
    heading: "Third-Party Cookies:",
    description:
      "We may allow third-party services to use cookies on our website to gather information and provide services. These third-party cookies are subject to the respective privacy policies of these providers.",
  },
  {
    id: 4,
    heading: "Your Choices",
    description:
      "You can control and manage cookies through your browser settings. You can choose to block or delete cookies, and you can also set your browser to notify you when cookies are being used.  ",
  },
  {
    id: 5,
    heading: "Consent",
    description:
      "By using our website, you consent to the use of cookies as described in this Cookie Policy. If you do not agree to the use of cookies, please adjust your browser settings accordingly or refrain from using our website. ",
  },
  {
    id: 6,
    heading: "Changes to the Cookie Policy",
    greenDescription: "info@airapplecart.co.uk.",
    description:
      "We may update this Cookie Policy from time to time. Any changes will be posted on this page. For more information about how we collect, use, and protect your data, please review our Privacy Policy. If you have any questions or concerns about our Cookie Policy, please contact us at ",
  },
];
export const CookiePolicyPage = () => {
  return (
    <>
      <PolicyFeature
        pageData={cookiePolicy}
        heading="Air Applecart Cookie Policy"
        description={`This Cookie Policy explains how ${AIR_APPLE_CART_NAME} uses cookies and similar technologies on our website to enhance your browsing experience and gather information. By accessing or using our website, you consent to the use of cookies as described in this policy.`}
      />
    </>
  );
};
