import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { billing_card_data, card_data } from "./pricing-data";
import { Link, useNavigate } from "react-router-dom";
import { BASE_FE_APP_URL } from "../../../config";

const user_details = {
    color: '#6B7280',
    fontSize: 14,
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: '400',
    lineHeight: '20px',
    wordWrap: 'break-word',
}
const user_detail_data = {
    color: '#1F2937',
    paddingTop: '5px',
    fontSize: 14,
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: '500',
    lineHeight: '20px',
    wordWrap: 'break-word',
}

export const PricingCards = () => {
    const [Card_Details_Length, setCard_Details_Length] = useState(2);
    
    const navigate = useNavigate();

    const handleClick = () => {
        if(Card_Details_Length == 2){
            setCard_Details_Length(billing_card_data?.length);
        }else{
            setCard_Details_Length(2);
        }
    }

  return (
    <Container maxWidth="xl" sx={{ marginTop: "7%" }}>
      <Box>
        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={7} xl={7}>
            <Grid container>
                {
                    card_data?.map((data, index) => (
                        <Grid xs={12} sm={12} md={6} lg={6} xl={6} sx={{display: 'flex', justifyContent: 'center'}}>
                            <Box
                            key={index}
                            sx={{
                                margin: '20px 20px',
                                maxWidth: "348px",
                                minHeight: "348px",
                                background: "white",
                                boxShadow: "0px 10px 62px rgba(0, 0, 0, 0.06)",
                                borderRadius: "8px",
                                padding: "25px",
                                position: "relative", // Set the container's position to relative
                            }}
                            >
                            
                            <Box
                                sx={{
                                padding: "10px",
                                maxWidth: "fit-content",
                                opacity: "0.58",
                                background: "#EBFAF8",
                                borderRadius: "50%",
                                }}
                            >
                                <img src="/assets/Pricing/triangle.svg" />
                            </Box>
                            {
                                data?.plan && (
                                    <div style={{position: 'relative', top: '-50px',height: '25px',paddingLeft: '12px', paddingRight: '12px', paddingTop: '2px', paddingBottom: '2px', background: '#EBFAF8', borderRadius: '16px', justifyContent: 'center', alignItems: 'center', display: 'inline-flex', float: 'right'}}>
                                        <div style={{textAlign: 'center', color: '#38CAB5', fontSize: '14px', fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>Growth</div>
                                    </div>
                                )
                            }
                           
                            <Typography
                                sx={{
                                width: "100%",
                                color: "#1F2937",
                                paddingTop: '12px',
                                fontSize: 20,
                                fontFamily: "Plus Jakarta Sans",
                                fontWeight: "600",
                                lineHeight: "24px",
                                wordWrap: "break-word",
                                }}
                            >
                                {data?.title}
                            </Typography>
                            <Typography
                                sx={{
                                width: "100%",
                                color: "#79839E",
                                paddingTop: '12px',
                                fontSize: 14,
                                fontFamily: "Plus Jakarta Sans",
                                fontWeight: "400",
                                lineHeight: "24px",
                                wordWrap: "break-word",
                                }}
                            >
                                {data?.description}
                            </Typography>
                            {/* user section */}
                            {
                                data?.plan ? (
                                    <React.Fragment>
                                        <div style={{width: '100%', border: '1px #E9EAEF solid', marginTop: '20px', marginBottom: '20px'}}></div>
                                        <Box mb={13}>
                                            <Grid container sx={{
                                                '@media (max-width: 600px)': {
                                                    textAlign: 'center'
                                                },
                                            }}>
                                                <Grid xs={12} sm={3} md={6} lg={3}>
                                                    <Typography sx={user_details}>Users</Typography>
                                                    <Typography sx={user_detail_data}>{data?.users}</Typography>
                                                </Grid>
                                                <Grid xs={12} sm={5} md={6} lg={5}>
                                                    <Typography sx={user_details}>Next Billing State</Typography>
                                                    <Typography sx={user_detail_data}>{data?.next_billing}</Typography>
                                                </Grid>
                                                <Grid xs={12} sm={4} md={6} lg={4}>
                                                    <Typography sx={user_details}>Price Estimate</Typography>
                                                    <Typography sx={user_detail_data}>{data?.estimate}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            '@media (min-width: 900px) and (max-width: 1200px)': {
                                                display: 'unset',
                                                },
                                        }}>

                                            <Button
                                                // variant="outlined"
                                                sx={{
                                                textTransform: 'capitalize',
                                                paddingBottom: "0px",
                                                background: "#fff",
                                                border: '1px solid lightgray',
                                                color: "#6B7280",
                                                width: "40%",
                                                padding: "10px",
                                                position: "absolute", // Set the button's position to absolute
                                                bottom: "25px", // Adjust the distance from the bottom as needed
                                                "&:hover": {
                                                    backgroundColor: "#fff", // Change the background color on hover to light green
                                                },
                                                '@media (min-width: 900px) and (max-width: 1200px)': {
                                                position: 'unset',
                                                marginBottom: '10px',
                                                width: '100%',
                                                },
                                                '@media (max-width: 370px)': {
                                                position: 'unset',
                                                marginBottom: '10px',
                                                width: '100%',
                                                },
                                                }}
                                            >
                                                Remove Plan
                                            </Button>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                textTransform: 'capitalize',
                                                paddingBottom: "0px",
                                                background: "#38CAB5",
                                                color: "#fff",
                                                width: "40%",
                                                padding: "10px",
                                                right: '20px',
                                                position: "absolute", // Set the button's position to absolute
                                                bottom: "25px", // Adjust the distance from the bottom as needed
                                                "&:hover": {
                                                    backgroundColor: "#38CAB5", // Change the background color on hover to light green
                                                },
                                                '@media (min-width: 900px) and (max-width: 1200px)': {
                                                    position: 'unset',
                                                    width: '100%',
                                                },
                                                '@media (max-width: 370px)': {
                                                    position: 'unset',
                                                    width: '100%',
                                                },
                                                }}
                                            >
                                                Change Plan
                                            </Button>
                                        </Box>
                                    </React.Fragment>
                                ) : (
                                    <Button
                                        variant="contained"
                                        sx={{
                                        textTransform: 'capitalize',
                                        paddingBottom: "0px",
                                        background: "#38CAB5",
                                        color: "#fff",
                                        width: "87%",
                                        padding: "10px",
                                        position: "absolute", // Set the button's position to absolute
                                        bottom: "25px", // Adjust the distance from the bottom as needed
                                        "&:hover": {
                                            backgroundColor: "#38CAB5", // Change the background color on hover to light green
                                        },
                                        }}
                                        onClick={() => navigate('/pricing/chooese-a-plan')}
                                    >
                                        Select Plan
                                    </Button>
                                )
                            }
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>
          </Grid>

          <Grid xs={12} sm={12} md={12} lg={5} xl={5}>
                <Card variant="outlined" sx={{marginTop: '20px', borderRadius: '10px', width: '100%', margin: '20px auto'}}>
                            <Box sx={{padding: '24px'}}>
                                <Typography sx={{color: '#1F2937', fontSize: 24, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word'}}>Bill Estimate</Typography>
                                <Typography sx={{color: '#6B7280', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>Sep 24, 2023 - Oct 14, 2023</Typography>
                    {
                        billing_card_data?.slice(0,Card_Details_Length)?.map((data, index) => (
                                <React.Fragment key={data?.id}>
                                <Box sx={{marginTop: '32px'}}>
                                    <Box sx={{display: 'flex'}}>
                                        <Box
                                        sx={{
                                        padding: "10px",
                                        maxWidth: "fit-content",
                                        opacity: "0.58",
                                        background: "#EBFAF8",
                                        borderRadius: "50%",
                                        }}
                                        >
                                            <img src="/assets/Pricing/triangle.svg" />
                                        </Box>
                                        &nbsp; &nbsp; &nbsp;
                                        <Box>
                                            <Typography sx={{color: '#1F2937', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word'}}>{data?.title}</Typography>
                                            <Typography sx={{color: '#6B7280', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', wordWrap: 'break-word'}}>Paid {data?.paid}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{position: 'relative', top: '-50px',height: '25px',paddingLeft: '12px', paddingRight: '12px', paddingTop: '2px', paddingBottom: '2px', background: '#EBFAF8', borderRadius: '16px', justifyContent: 'center', alignItems: 'center', display: 'inline-flex', float: 'right', '@media (max-width: 450px)' : {display: 'none'}}}>
                                        <div style={{textAlign: 'center', color: '#38CAB5', fontSize: '14px', fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>Growth</div>
                                    </Box>
                                    {/* growth button */}
                                </Box>
                                <div style={{width: '100%', height: '100%', border: '1px #E9EAEF solid', marginTop: '20px', marginBottom: '20px'}}></div>
                                    {
                                        data?.pricing_details?.map((val, index) => (
                                            <React.Fragment key={val?.id}>
                                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                                <Typography sx={{color: '#6B7280', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', wordWrap: 'break-word'}}>{val?.detail}</Typography>
                                                <Typography sx={{color: '#1F2937', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{val?.price}</Typography>
                                            </Box>
                                            <br/>
                                            </React.Fragment>
                                        ))
                                    }
                                
                                <div style={{width: '100%', height: '100%', border: '1px #E9EAEF solid', marginTop: '10px', marginBottom: '20px'}}></div>
                                
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography sx={{color: '#6B7280', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', wordWrap: 'break-word'}}>Total</Typography>
                                    <Typography sx={{color: '#1F2937', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word'}}>£650.88</Typography>
                                </Box>
                                </React.Fragment>
                        ))
                    }
                            </Box>
                        <div style={{width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex', marginTop: '20px', marginBottom: '10px', padding: '24px', cursor: 'pointer'}} onClick={handleClick}>
                            <div style={{color: '#38CAB5', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>{Card_Details_Length > 2 ? 'Show Less' : 'Show more'}</div>
                        </div>
                    <Box sx={{background: '#F9FAFB', padding: '24px'}}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography sx={{color: '#6B7280', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', wordWrap: 'break-word'}}>Total Discount</Typography>
                            <Typography sx={{color: '#1F2937', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>-£10</Typography>
                        </Box>
                        <br/>
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography sx={{color: '#6B7280', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', wordWrap: 'break-word'}}>Total Tax</Typography>
                            <Typography sx={{color: '#1F2937', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', wordWrap: 'break-word'}}>£110.48</Typography>
                        </Box>

                        <div style={{width: '100%', height: '100%', border: '1px #E9EAEF solid', marginTop: '12px', marginBottom: '16px'}}></div>
                        
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography sx={{color: '#6B7280', fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', wordWrap: 'break-word'}}>Total</Typography>
                            <Typography sx={{color: '#1F2937', fontSize: 18, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', wordWrap: 'break-word'}}>£1200.65</Typography>
                        </Box>
                        <Link to={`${BASE_FE_APP_URL}/sign-up`}>
                        <Button
                            variant="contained"
                            sx={{
                            textTransform: 'capitalize',
                            marginTop: '20px',
                            background: "#38CAB5",
                            color: "#fff",
                            width: "100%",
                            padding: "10px",
                            "&:hover": {
                                    backgroundColor: "#38CAB5", // Change the background color on hover to light green
                                },
                            }}
                        >
                            Get Started
                        </Button>
                        </Link>
                    </Box>
                </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
