export const hero = [
    {
        title: 'Call Center',
        section: 'optimise Your Sales with Our Advanced Call Centre Solutions',
        description: `Welcome to our comprehensive suite of call centre solutions designed to revolutionise your sales operations. A call centre serves as the heartbeat of sales activities, providing a centralised hub for customer interactions and driving revenue growth.`
    }
];

export const features = [
    {
        id: 1,
        title: 'Dashboard',
        description: 'Track call centre performance in real-time with our Live Dashboard. Interactive visuals, automatic updates – effortless insights for success.',
        img: '/assets/Products/dashboard-call.svg',
        icon_color: '#ECFFFC',
    },
    {
        id: 2,
        title: 'Call Metrics',
        description: 'Enhance your call centre operations with Call Metrics, offering invaluable insights into performance and efficiency.',
        img: '/assets/Products/call-metrics.svg',
        icon_color: '#F2F9FC'
    },
    {
        id: 4,
        title: 'Power Dialler',
        description: 'Boost sales efficiency with our Power Dialler. Automate dialling for personalised communication, driving success.',
        img: '/assets/Products/ph_phone-light.svg',
        icon_color: '#FFF4F6'
    },
];

export const benefits = [
    {
        description: `Empower your business with a dedicated call centre, delivering exceptional customer service and unlocking sales opportunities. Centralising communication channels ensures prompt and professional support, bolstering customer satisfaction and brand reputation.`,
        image: '/assets/Products/benefits-call-center.svg',
        benefit: [
            {
                id: 1,
                title: 'Enhanced Customer Service',
                description: `Provide superior support to your customers with dedicated agents available to assist them promptly and professionally.`,
                img: '/assets/Products/headphone-call.svg',
            },
            {
                id: 2,
                title: 'Increased Sales Opportunities',
                description: `Utilise outbound calling to reach potential leads, upsell products or services, and maximise revenue generation.`,
                img: '/assets/Products/status-up.svg',
            },
            {
                id: 3,
                title: 'Enhanced Brand Reputation',
                description: `Delivering excellent customer experiences through your call centre can strengthen your brand reputation and encourage customer loyalty`,
                img: '/assets/Products/award.svg',
            },
            {
                id: 4,
                title: 'Data-Driven Insights',
                description: `Gather valuable customer data and insights from call interactions, helping you to understand customer needs better and refine your marketing and sales strategies.`,
                img: '/assets/Products/analytical-product.svg',
            },
        ]
    }
];

export const joinUs = {
    title: 'Ready to Dive into the Call Centre Experience?',
    description: `Embrace growth, collaboration, and success as we strive for excellence together. Join us on this rewarding journey today!`,
    img: '/assets/Products/air-call-center-join.svg',
}