import logo from './logo.svg';
import './App.css';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Layout } from './Components/Layout';
import { Home } from './Components/Pages/Home/Home';
import { Route, Routes } from 'react-router-dom';
import { Air_Marketer } from './Components/Pages/Products/air-marketer/air-marketer';
import { Air_Sales } from './Components/Pages/Products/air-sales/air-sales';
import { Air_Service } from './Components/Pages/Products/air-services/air-service';
import { Air_Operation } from './Components/Pages/Products/air-operations/air-operation';
import { Air_Calendar } from './Components/Pages/Products/air-calendar/air-calendar';
import { Air_Flat_Sign } from './Components/Pages/Products/air-flat-sign/air-flat-sign';
import { Air_Stenogofer } from './Components/Pages/Products/air-stenogofer/air-stenogofer';
import { Air_Mlm } from './Components/Pages/Products/air-mlm/air-mlm';
import { Air_Grammer_Tok } from './Components/Pages/Products/air-grammer-tok/air-grammer-tok';
import { Contact_Us } from './Components/Pages/ContactUs/contact-us';
import { Careers } from './Components/Pages/Careers/Careers';
import { ChooseAPlan } from './Components/Pages/ChooseAPlan/ChooseAPlan';
import { PricingMain } from './Components/Pages/Pricing/pricing';
import { CookiePolicyPage } from './Components/Pages/PolicyPage/CookiePolicyPage';
import { PrivacyPolicyPage } from './Components/Pages/PolicyPage/PrivacyPolicyPage';
import { DataPolicyPage } from './Components/Pages/PolicyPage/DataPolicyPage';
import { Register } from './Components/Pages/Register/register';
import { PartnerWithUs } from './Components/Pages/PartnerWithUs/PartnerWithUs';
import { Login } from './Components/Pages/Login/Login';
import { VerificationMain } from './Components/Pages/Verification/Verification';
import { ForgetPassword } from './Components/Pages/ForgetPassword/ForgetPassword';
import { ResetPassword } from './Components/Pages/ResetPassword/ResetPassword';
import { AboutUs } from './Components/Pages/AboutUs/AboutUs';
import { AirCallCenterPage } from './Components/Pages/Products/air-call-center/air-call-center';
import { AirLoyaltyProgramPage } from './Components/Pages/Products/air-loyalty-program/air-loyalty-program';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout><Home /></Layout>} />
      <Route path="/about-us" element={<Layout><AboutUs /></Layout>} />
      <Route path="/signup" element={<Register />} />
      <Route path="/signin" element={<Login />} />
      <Route path="/verification" element={<VerificationMain />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/product/air-marketer" element={<Layout><Air_Marketer /></Layout>} />
      <Route path="/product/air-sales" element={<Layout><Air_Sales /></Layout>} />
      <Route path="/product/air-service" element={<Layout><Air_Service /></Layout>} />
      <Route path="/product/air-operation" element={<Layout><Air_Operation /></Layout>} />
      <Route path="/product/air-loyalty-program" element={<Layout><AirLoyaltyProgramPage /></Layout>} />
      <Route path="/product/air-call-center" element={<Layout><AirCallCenterPage /></Layout>} />
      <Route path="/product/air-calander" element={<Layout><Air_Calendar /></Layout>} />
      <Route path="/product/air-fiat-sign" element={<Layout><Air_Flat_Sign /></Layout>} />
      <Route path="/product/air-stenogofer" element={<Layout><Air_Stenogofer /></Layout>} />
      <Route path="/product/air-mlm" element={<Layout><Air_Mlm /></Layout>} />
      <Route path="/product/air-grammer-tok" element={<Layout><Air_Grammer_Tok /></Layout>} />
      <Route path="/contact-us" element={<Layout><Contact_Us /></Layout>} />
      <Route path="/careers" element={<Layout><Careers/></Layout>} />
      <Route path="/pricing" element={<Layout><PricingMain /></Layout>} />
      <Route path="/pricing/chooese-a-plan" element={<Layout><ChooseAPlan/></Layout>} />
      <Route path="/cookie-policy" element={<Layout><CookiePolicyPage/></Layout>} />
      <Route path="/privacy-policy" element={<Layout><PrivacyPolicyPage/></Layout>} />
      {/* <Route path="/data-policy" element={<Layout><DataPolicyPage/></Layout>} /> */}
      <Route path="/terms-and-conditions" element={<Layout><DataPolicyPage/></Layout>} />
      {/* <Route path="/partner-with-us" element={<Layout><PartnerWithUs/></Layout>} /> */}
    </Routes>
  );
}

export default App;