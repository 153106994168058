import React from 'react'
import './signin.scss';
import {
    Box,
    Button,
    Card,
    Container,
    Typography,
  } from "@mui/material";
  import WhiteCustomField from "../../Pages/PartnerWithUs/white-custom-field";
import { useNavigate } from 'react-router-dom';

export const SigninForm = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Box className="signin-form">
        <Typography className="welcome-title">
         Sign In to Air Applecart
        </Typography>
        <Typography className="get-started-title">Let's Get Started</Typography>
        <br />
        <Card
          className="sign-form-card"
          variant="outlined"
        >
            <Box>
              <label className='input-label'>
                Email <span className="form-esteric">*</span>
              </label>
              <input className="input-field" placeholder="Enter Email" />
              <br />
              <label className='input-label'>
                Password <span className="form-esteric">*</span>
              </label>
              <WhiteCustomField fieldPlaceholder='Enter Password' endIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15.5095 12.7C15.2495 14.11 14.0995 15.26 12.6895 15.52" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9.47 14.53L2 22" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M21.9993 2L14.5293 9.47" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>} fieldType='password' />
              {/* <p style={{ color: '#4B5563', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', lineHeight: '18px', wordWrap: 'break-word'}}>The Password must be at least 8 characters  long having 1 capital letter, 1 small letter and 1 numeric digit</p> */}
              <br/>
            </Box>
          <Button
            sx={{
              width: "100%",
              background: "#38CAB5",
              color: "#fff",
              padding: "10px",
              fontSize: '16px',
              fontFamily: 'Plus Jakarta Sans',
              fontWeight: '500',
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#38CAB5", // Change the background color on hover to light green
              },
            }}
            variant="contained"
          >
            Signin
          </Button>
          <br/>
          <br/>
        <Box sx={{textAlign: 'center'}}>
            <Typography className='forgot-password' onClick={()=>navigate('/forgot-password')}>Forgot Password?</Typography>
        </Box>
        </Card>
      </Box>
    </Container>
  )
}
