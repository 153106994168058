import { TextField, InputAdornment } from "@mui/material";
import React from "react";

const WhiteCustomField = ({
  label,
  fieldType,
  fieldPlaceholder,
  endIcon,
  require,
  multiline = false,
  rows,
}) => {

  const inputProps = {
    style: {
      padding: "10px 16px",
    },
  };

  return (
    <div>
      <label className="label-color secondary-title font-family-lato font-weight-500">
        {label} {require && <span className="error-color">*</span>}
      </label>
      <TextField
        fullWidth
        multiline={multiline}
        rows={rows}
        placeholder={fieldPlaceholder}
        sx={fieldStyles}
        inputProps={multiline ? false : inputProps}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
        }}
        className="margin-top-0"
        type={fieldType}
      />
    </div>
  );
};

export default WhiteCustomField;
export const fieldStyles = {
  ".MuiOutlinedInput-notchedOutline": { border: "0px " },
  "& .MuiInputBase-input": {
    color: "#9CA3AF",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: `Plus Jakarta Sans`,
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    border: "3px solid #CFF2ED",
  },
  "& .MuiOutlinedInput-root": {
    border: '1px solid #E8E8ED',
    borderRadius: "8px",
    "&.Mui-focused fieldset": {
      border: "3px solid #CFF2ED",
    },
  },
};