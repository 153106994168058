import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1, position: "absolute", width: "100%", zIndex: '1'}}>
      <AppBar
        position="static"
        sx={{
          background: "none",
          boxShadow: "none",
          paddingLeft: "50px",
          paddingRight: "50px",
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between'}}>
          <Box className="image-box" sx={{cursor: 'pointer'}}>
            <img
              src={"assets/Home/Logo.png"}
              alt="Air Applecart Logo"
              width={153}
              height={38}
              loading="lazy"
              onClick={() => navigate('/')}
            />
          </Box>
          <Button
            sx={{
              background: "#38CAB5",
              color: "#fff",
              padding: "5px 15px 5px 15px",
              fontSize: '16px',
              fontFamily: 'Plus Jakarta Sans',
              fontWeight: '500',
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#38CAB5", // Change the background color on hover to light green
              },
            }}
            variant="contained"
            onClick={()=>navigate('/signin')}
          >
            Signin
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
