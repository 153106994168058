import { IconButton, InputAdornment } from "@mui/material";
import { RHFTextField } from "../../Common/react-hook-form";
import * as Yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const upsertPartnerWithUsValidationSchema = Yup?.object()?.shape({
  firstName: Yup?.string()?.trim()?.required("First Name is Required"),
  lastName: Yup?.string()?.trim()?.required("Last Name is Required"),
  email: Yup?.string()
    ?.trim()
    ?.email()
    ?.typeError("Invalid email type")
    ?.required("Email is Required"),
  businessName: Yup?.string()?.trim(),
  companyRegistrationNumber: Yup?.string()
    ?.trim(),
    // ?.required("Company Registration Name is Required"),
  // servicesOffered: Yup?.string()
  //   ?.trim()
  //   ?.required("Services Offered is Required"),
  serviceDescription: Yup?.string()
    ?.trim()
    ?.required("Service Description is Required"),
  // comments: Yup?.string()?.trim()?.required("Comments is Required"),
  password: Yup?.string()?.trim()?.required("Password is Required")?.max(30, 'Password should be less than 30 characters')
  ?.min(8, 'Password should contain at least 8 characters')
  ?.matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,30}$/,
    'Password must include at least 1 capital letter, 1 small letter, 1 numeric digit, and 1 special character',
  ),
  confirmPassword: Yup?.string()
    ?.trim()
    ?.required("Confirm Password is Required")
    ?.oneOf([Yup.ref("password")], "Passwords do not match"),
});

export const upsertPartnerWithUsDefaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  businessName: "",
  companyRegistrationNumber: "",
  servicesOffered: "",
  serviceDescription: "",
  comments: "",
  password: "",
  confirmPassword: "",
};

export const upsertPartnerWithUsFormFields = (
  togglePasswordVisibility,
  passwordVisibility
) => [
  {
    id: 1,
    componentProps: {
      name: "firstName",
      label: "First Name",
      placeholder: "First Name",
      fullWidth: true,
      required: true,
    },
    md: 6,
    component: RHFTextField,
  },
  {
    id: 2,
    componentProps: {
      name: "lastName",
      label: "Last Name",
      placeholder: "Last Name",
      fullWidth: true,
      required: true,
    },
    md: 6,
    component: RHFTextField,
  },
  {
    id: 3,
    componentProps: {
      name: "email",
      label: "Email",
      placeholder: "Email",
      fullWidth: true,
      required: true,
    },
    md: 6,
    component: RHFTextField,
  },
  {
    id: 4,
    componentProps: {
      name: "businessName",
      label: "Business Name",
      placeholder: "Business Name",
      fullWidth: true,
      required: true,
    },
    md: 6,
    component: RHFTextField,
  },
  {
    id: 5,
    componentProps: {
      name: "companyRegistrationNumber",
      label: "Company Registration Number",
      placeholder: "Company Registration Number",
      fullWidth: true,
      required: true,
    },
    md: 12,
    component: RHFTextField,
  },
  // {
  //   id: 6,
  //   componentProps: {
  //     name: "servicesOffered",
  //     label: "Services Offered",
  //     placeholder: "Services Offered",
  //     fullWidth: true,
  //     required: true,
  //   },
  //   md: 12,
  //   component: RHFTextField,
  // },
  {
    id: 7,
    componentProps: {
      name: "serviceDescription",
      label: "Service Description",
      placeholder: "Service Description",
      fullWidth: true,
      required: true,
      multiline:true,
      rows:4
    },
    md: 12,
    component: RHFTextField,
  },
  // {
  //   id: 8,
  //   componentProps: {
  //     name: "comments",
  //     label: "Comments",
  //     placeholder: "Comments",
  //     fullWidth: true,
  //     multiline: true,
  //     minRows: 3,
  //   },
  //   md: 12,
  //   component: RHFTextField,
  // },
  {
    id: 9,
    componentProps: {
      name: "password",
      label: "Enter Password",
      placeholder: "Enter Password",
      fullWidth: true,
      required: true,
      type: passwordVisibility?.password ? "text" : "password",
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => togglePasswordVisibility("password")}>
              {passwordVisibility?.password ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      },
    },
    md: 6,
    component: RHFTextField,
  },
  {
    id: 10,
    componentProps: {
      name: "confirmPassword",
      label: "Confirm Password",
      placeholder: "Confirm Password",
      fullWidth: true,
      required: true,
      type: passwordVisibility?.confirmPassword ? "text" : "password",
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => togglePasswordVisibility("confirmPassword")}
            >
              {passwordVisibility?.confirmPassword ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      },
    },
    md: 6,
    component: RHFTextField,
  },
];
