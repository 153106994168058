import React from 'react'
import { Hero } from '../../Common/Products/hero-section/Hero'
import { Box, Container, Typography } from '@mui/material';
import { FAQ } from './../Home/Faq/FAQ';
import { ExploreSection } from '../Home/apple-cart2/ExploreSection';
import { Team } from '../../Features/team';

export const AboutUs = () => {
  return (
    <>
        <Container>
            <Hero hero={[{ title: "About Us", description: "Driving success through innovation, Air Applecart propels businesses forward in the digital era with pioneering solutions." }]} page={true} />
            <Box className="joinUs-image" style={{width:'100%'}}>
                <img  alt='bg' src="assets/Aboutus/about-us.jpg" style={{width:'100%'}}/>
            </Box>
            <Box>
                <Box style={{margin: '50px 50px'}}>
                    <Box style={{display: 'flex', justifyContent: 'center', marginBottom: '11px'}}>
                        <span className='heading-top'>Welcome to Air Applecart</span>
                    </Box>
                    <Typography className='heading-typo'>
                        Where we're passionate about revolutionising the way businesses operate in the digital era. Our focus is to provide innovative solutions that simplify the complexities of customer relationship management, sales automation, and business growth. Through our dedication to excellence and continuous innovation, we aim to empower businesses of all sizes to thrive in an ever-evolving marketplace.
                    </Typography>
                </Box>
                <ExploreSection
                    title={'Our Mission'}
                    description={`At Air Applecart, we're on a mission to transform how businesses navigate the digital landscape. Our goal is to offer cutting-edge solutions that streamline customer relationship management, automate sales processes, and foster business growth. With a relentless commitment to excellence and ongoing innovation, we empower businesses of all scales to excel in today's dynamic market.`}
                    aboutUs={true}
                 />
                 <Team/>
                <FAQ />
            </Box>
        </Container>
    </>
  )
}
