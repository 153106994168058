import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, Typography } from '@mui/material';

// Custom Label Component
const CustomLabel = (props: any) => {
  const { label, required = false, marginBottom = 0.6 } = props;
  return (
    <Typography
      sx={{
        color: '#757575', 
        marginBottom: `${marginBottom}rem`,
      }}
      variant="body2"
      fontWeight={500}
    >
      {label}
      {required && (
        <Typography color="red" component="span">
          {' '}
          *{' '}
        </Typography>
      )}
    </Typography>
  );
};
export default function RHFTextField({ name, required, ...other }: any) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          {other.label && (
            <CustomLabel label={other.label} required={required} />
          )}
          <TextField
            {...field}
            fullWidth
            error={!!error}
            helperText={
              <Typography
                component={'span'}
                sx={{ display: 'block', ml:-1.7  }}
              >
                {error?.message}
              </Typography>
            }
            FormHelperTextProps={{
              classes: {
                root: '',
                color: 'green',
              },
            }}
            {...other}
            label=""
          />
        </>
      )}
    />
  );
}
