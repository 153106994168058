import React from 'react';
import './join-us.scss';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { BASE_FE_APP_URL } from '../../../../config';

export const JoinUs = ({ joinUs }) => {
    return (
        <React.Fragment>
            <Box className='joinUs'>
                <Box className='joinUs-box1'>
                    <Typography className='join-title'>{joinUs?.title}</Typography>
                </Box>
                <br />
                <Box className='joinUs-box2'>
                    <Typography className='join-desc'><span>{joinUs?.description}</span></Typography>
                </Box>
                <Box className="btn-join-parent" sx={{ textAlign: 'center', marginTop: '40px',mb:'64px' }}>
                    <Link to={`${BASE_FE_APP_URL}/sign-up`}>
                        <Button variant='contained' className='btn-join' >Join Us Today</Button>
                    </Link>
                </Box>
            <Box className="joinUs-image">
                <img  alt='bg' src={joinUs?.img} />
            </Box>
            </Box>
            <Box sx={{height:'300px'}} >
                
            </Box>
        </React.Fragment>
    )
}
