// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-box {
  height: 100vh;
}
.account-box .account-container .account-left-side {
  position: relative;
  top: 100px;
  padding-left: 50px;
}
.account-box .account-container .account-right-side {
  position: fixed;
  right: 0;
  width: 100%;
  text-align: center;
  background: #F8F9FA;
  height: 100vh;
}
.account-box .account-container .account-right-side .right-side-image {
  position: relative;
  top: 17%;
}
.account-box .account-container .account-right-side .right-side-image img {
  width: 90%;
}
@media screen and (max-width: 1100px) {
  .account-box .account-container .account-right-side {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Accounts/accounts.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAEQ;EACI,kBAAA;EACA,UAAA;EACA,kBAAA;AAAZ;AAOQ;EACI,eAAA;EACA,QAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;AALZ;AAOY;EACI,kBAAA;EACA,QAAA;AALhB;AAMgB;EACI,UAAA;AAJpB;AASQ;EACI;IACE,aAAA;EAPZ;AACF","sourcesContent":[".account-box {\n    height: 100vh;\n    .account-container{\n        \n        .account-left-side{ \n            position: relative;\n            top: 100px;   \n            padding-left: 50px;\n            // height: 100vh;\n            // overflow-y: auto;\n        }\n        // .account-left-side::-webkit-scrollbar {\n        //     display: none;\n        // }\n        .account-right-side{\n            position: fixed;\n            right: 0;\n            width: 100%;\n            text-align: center;\n            background: #F8F9FA;\n            height: 100vh;\n            \n            .right-side-image{\n                position: relative;\n                top: 17%;\n                img{\n                    width: 90%;\n                    // height: 100%;\n                }\n            }\n        }\n        @media screen and (max-width: 1100px) {\n            .account-right-side {\n              display: none;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
