import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import "./contact-us.scss";
import { FormProvider, RHFTextField } from "../../Common/react-hook-form";
import { useForm } from "react-hook-form";
import { BASE_API_ENQUIRY, BASE_API_ENQUIRY_API_KEY } from "../../../config";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";

export const ContactFormSection = () => {
  const method =  useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string()
          .matches(/^[a-zA-Z\s]+$/, {
            message: "Name can only contain letters and spaces",
            excludeEmptyString: true, 
          })
          .trim()
          .required("Name is required"),
        email: Yup.string()
          .trim()
          .email("Invalid email format")
          .required("Email is Required"),
        query: Yup.string().trim().required("Query is Required"),
        phoneNumber: Yup.string()
          .trim()
          .matches(/^\+44[0-9]{10}$/, "Phone number must be a valid UK number")
          .required("Phone number is Required"),
      })
    ),
    defaultValues: { name: "", email: "", query: "", phoneNumber: "" },
  });
  const { handleSubmit, reset } = method;

  const submitEnquiry = async (formData) => {
    try {
      const response = await axios?.post(
        `${BASE_API_ENQUIRY}/enquiries`,
        formData,
        {
          headers: {
            "x-api-key": BASE_API_ENQUIRY_API_KEY,
          },
        }
      );
      enqueueSnackbar(response?.data?.message, {
        variant: "success",
      });
      reset?.();
    } catch (error) {
      enqueueSnackbar(error?.data?.message ?? "Something went wrong", {
        variant: "error",
      });
    }
  };

  return (
    <Box className="contact-us-section">
      <Container maxWidth="md" className="contact-us-form">
        <Box className="contact-title-section">
          <Typography className="contact-title">Contact Us</Typography>
        </Box>
        <FormProvider methods={method} onSubmit={handleSubmit(submitEnquiry)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <RHFTextField
                name="name"
                size="small"
                required
                fullWidth
                label="Name"
                placeholder="Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField
                name="email"
                size="small"
                required
                fullWidth
                label="Email"
                placeholder="Email"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <RHFTextField
                name="phoneNumber"
                size="small"
                required
                fullWidth
                label="Phone Number"
                placeholder="Phone Number"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <RHFTextField
                name="query"
                size="small"
                required
                fullWidth
                label="Enquiry"
                placeholder="Enquiry"
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
          <br />
          <Button className="btn-form" type="submit">
            Send Message
          </Button>
        </FormProvider>
      </Container>
    </Box>
  );
};
