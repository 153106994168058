// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-nav {
  padding: 20px;
  color: #1F2937;
  font-size: 16px;
  font-family: "Plus Jakarta Sans";
  font-weight: 400;
  line-height: 26px;
  word-wrap: break-word;
}

.right-reserved {
  color: #1F2937;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.footer-icons {
  margin-right: 12px;
}

.below-footer-icons {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 500px) {
  .footer-links {
    display: unset !important;
  }
}
@media screen and (max-width: 720px) {
  .below-footer-icons {
    display: unset;
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/Footer/footer.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,cAAA;EACA,eAAA;EACA,gCAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,gCAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AACA;EACI,aAAA;EACA,8BAAA;AAEJ;;AACA;EACI;IACI,yBAAA;EAEN;AACF;AACA;EACI;IACI,cAAA;IACA,kBAAA;EACN;AACF","sourcesContent":[".footer-nav{\n    padding: 20px;\n    color: #1F2937;\n    font-size: 16px;\n    font-family: \"Plus Jakarta Sans\";\n    font-weight: 400;\n    line-height: 26px;\n    word-wrap: break-word\n}\n\n.right-reserved{\n    color: #1F2937;\n    font-size: 14px;\n    font-family: \"Plus Jakarta Sans\";\n    font-weight: 400;\n    line-height: 24px;\n    word-wrap: break-word\n}\n\n.footer-icons{\n    margin-right: 12px;\n}\n.below-footer-icons{\n    display: flex;\n    justify-content: space-between;\n}\n\n@media screen and (max-width: 500px) {\n    .footer-links {\n        display: unset !important;\n    }\n}\n\n@media screen and (max-width: 720px) {\n    .below-footer-icons {\n        display: unset;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
