import { Box, Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { Hero } from "../../Common/Products/hero-section/Hero";
import { Link } from "react-router-dom";
import {
  AIR_APPLE_CART__OLD_NAME,
  BASE_API_ENQUIRY,
  BASE_FE_APP_URL,
} from "../../../config";
import { FormProvider } from "../../Common/react-hook-form";
import {
  upsertPartnerWithUsDefaultValues,
  upsertPartnerWithUsFormFields,
  upsertPartnerWithUsValidationSchema,
} from "./PartnerWithUs.data";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";

export const PartnerWithUs = () => {
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const method = useForm({
    resolver: yupResolver(upsertPartnerWithUsValidationSchema),
    defaultValues: upsertPartnerWithUsDefaultValues,
  });

  const { handleSubmit, reset } = method;

  const submitPartnerWithUs = async (formData) => {
    console.log(formData);
    enqueueSnackbar("Form submitted successfully", {
      variant: "success",
    });
    reset?.();
    return;
    console.log(formData);
    try {
      const response = await axios?.post(
        `${BASE_API_ENQUIRY}/enquiries`,
        formData,
        {}
      );
      enqueueSnackbar(response?.data?.message, {
        variant: "success",
      });
      reset?.();
    } catch (error) {
      enqueueSnackbar(error?.data?.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Box
        className="PartnerWithUs ContactUs"
        sx={{ position: "relative", zIndex: "9" }}
      >
        <Hero
          hero={[
            {
              title: "Create your Delegate Account",
              // description: "Sign up with name, email address and a password",
            },
          ]}
          page={true}
        />
        <Box className="contact-us-section">
          <Container
            sx={{
              borderRadius: "12px",
              px: { lg: "100px", xs: "20px" },
              bgcolor: "#FFF",
              boxShadow: "0px 31.00949px 64px 0px rgba(0, 0, 0, 0.08)",
              display: "flex",
              flexDirection: "column",
              zIndex: "0",
            }}
            maxWidth="md"
          >
            <Box
              sx={{
                fontSize: "32px",
                fontWeight: 600,
                color: "#1D293F",
                textAlign: "center",
                my: "40px",
                position: "relative",
                zIndex: "9",
              }}
            >
              {/* Create Account */}
            </Box>
            {/* <form>
              <Grid
                sx={{ position: "relative", zIndex: "9" }}
                container
                gap={"24px"}
                justifyContent="center"
                mb="50px"
              >
                <Grid xs={12} lg={5.5}>
                  <WhiteCustomField fieldPlaceholder="First Name" />
                </Grid>
                <Grid xs={12} lg={6}>
                  <WhiteCustomField fieldPlaceholder="Last Name" />
                </Grid>
                <Grid xs={12} lg={5.5}>
                  <WhiteCustomField
                    fieldPlaceholder="Email"
                    fieldType="email"
                  />
                </Grid>
                <Grid xs={12} lg={6}>
                  <WhiteCustomField fieldPlaceholder="Business name" />
                </Grid>
                <Grid xs={11.9}>
                  <WhiteCustomField fieldPlaceholder="Company Registration Number" />
                </Grid>
                <Grid xs={11.9}>
                  <WhiteCustomField fieldPlaceholder="Service Offered" />
                </Grid>
                <Grid xs={11.9}>
                  <WhiteCustomField fieldPlaceholder="Service Description" />
                </Grid>
                <Grid xs={11.9}>
                  <WhiteCustomField
                    multiline={true}
                    rows={3}
                    fieldPlaceholder="Comments"
                  />
                </Grid>
                <Grid xs={12} lg={5.5}>
                  <WhiteCustomField
                    fieldPlaceholder="Enter Password"
                    endIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
                          stroke="#6B7280"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77"
                          stroke="#6B7280"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993"
                          stroke="#6B7280"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.5095 12.7C15.2495 14.11 14.0995 15.26 12.6895 15.52"
                          stroke="#6B7280"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.47 14.53L2 22"
                          stroke="#6B7280"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21.9993 2L14.5293 9.47"
                          stroke="#6B7280"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                    fieldType="password"
                  />
                </Grid>
                <Grid xs={12} lg={6}>
                  <WhiteCustomField
                    fieldPlaceholder="Confirm Password"
                    endIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
                          stroke="#6B7280"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77"
                          stroke="#6B7280"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993"
                          stroke="#6B7280"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.5095 12.7C15.2495 14.11 14.0995 15.26 12.6895 15.52"
                          stroke="#6B7280"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.47 14.53L2 22"
                          stroke="#6B7280"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21.9993 2L14.5293 9.47"
                          stroke="#6B7280"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                    fieldType="password"
                  />
                </Grid>
                <Grid xs={11.9} display="flex" justifyContent="center">
                  <Button type="submit" className="btn-form">
                    Send Message
                  </Button>
                </Grid>
                <Grid xs={11.9}>
                  <Box
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#111827",
                      textAlign: "center",
                    }}
                  >
                    Already have an account?{" "}
                    <Link
                      to={`${BASE_FE_APP_URL}/login`}
                      style={{ color: "#38CAB5", textDecoration: "none" }}
                    >
                      Sign In
                    </Link>{" "}
                  </Box>
                </Grid>
                <Grid xs={11.9}>
                  <Box
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#6B7280",
                      textAlign: "center",
                    }}
                  >
                    By Signing up, you are creating a guest account, and you
                    agree to{" "}
                    <span style={{ color: "#111827" }}>Air Applecart</span>
                  </Box>
                </Grid>
              </Grid>
            </form> */}
            <FormProvider
              methods={method}
              onSubmit={handleSubmit(submitPartnerWithUs)}
            >
              <Grid container spacing={1}>
                {upsertPartnerWithUsFormFields(
                  togglePasswordVisibility,
                  passwordVisibility
                )?.map((item) => (
                  <Grid item xs={12} md={item?.md} key={item?.id}>
                    <item.component {...item?.componentProps} size={"small"} />
                  </Grid>
                ))}
              </Grid>
              <Button type="submit" className="btn-form" sx={{ my: 2 }}>
                Sign Up
              </Button>
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#111827",
                  textAlign: "center",
                  my: 2,
                }}
              >
                Already have an account?{" "}
                <Link
                  to={`${BASE_FE_APP_URL}/login`}
                  style={{ color: "#38CAB5", textDecoration: "none" }}
                >
                  Sign In
                </Link>{" "}
              </Box>
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#6B7280",
                  textAlign: "center",
                  my: 2,
                }}
              >
                By Signing up, you are creating a delegate member account, and
                you agree to{" "}
                <span style={{ color: "#111827" }}>
                  {AIR_APPLE_CART__OLD_NAME}
                </span>{" "}
                terms and conditions
              </Box>
              <br />
            </FormProvider>
          </Container>
        </Box>
      </Box>
    </>
  );
};
export const fieldStyles = {
  ".MuiOutlinedInput-notchedOutline": { border: "0px " },
  "& .MuiInputBase-input": {
    color: "#A1A4B6",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: `'Lato', sans-serif`,
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    border: "3px solid #CFF2ED",
  },
  "& .MuiOutlinedInput-root": {
    border: "1px solid #E8E8ED",
    borderRadius: "8px",
    "&.Mui-focused fieldset": {
      border: "3px solid #CFF2ED",
    },
  },
};
