// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.benefit-section .benefit-title {
  color: #1F2937;
  font-size: 40px;
  font-family: Plus Jakarta Sans;
  font-weight: 600;
  line-height: 48px;
  word-wrap: break-word;
}
.benefit-section .benefit-description {
  width: 90%;
  color: #6B7280;
  font-size: 16px;
  font-family: Plus Jakarta Sans;
  font-weight: 500;
  line-height: 32px;
  word-wrap: break-word;
}
.benefit-section .benefit-list {
  width: 80%;
}
.benefit-section .benefit-list .benefit-list-title {
  color: #1F2937;
  font-size: 20px;
  font-family: Plus Jakarta Sans;
  font-weight: 500;
  line-height: 32px;
  word-wrap: break-word;
}
.benefit-section .benefit-list .benefit-list-description {
  color: #6B7280;
  font-size: 16px;
  font-family: Plus Jakarta Sans;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Products/benefits-section/benefit-section.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,eAAA;EACA,8BAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AAAR;AAEI;EACI,UAAA;EACA,cAAA;EACA,eAAA;EACA,8BAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AAAR;AAEI;EACI,UAAA;AAAR;AACQ;EACI,cAAA;EACA,eAAA;EACA,8BAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AACZ;AACQ;EACI,cAAA;EACA,eAAA;EACA,8BAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AACZ","sourcesContent":[".benefit-section{\n    .benefit-title{\n        color: #1F2937;\n        font-size: 40px;\n        font-family: Plus Jakarta Sans;\n        font-weight: 600;\n        line-height: 48px;\n        word-wrap: break-word\n    }\n    .benefit-description{\n        width: 90%;\n        color: #6B7280;\n        font-size: 16px;\n        font-family: Plus Jakarta Sans;\n        font-weight: 500;\n        line-height: 32px;\n        word-wrap: break-word\n    }\n    .benefit-list{\n        width: 80%;\n        .benefit-list-title{\n            color: #1F2937;\n            font-size: 20px;\n            font-family: Plus Jakarta Sans;\n            font-weight: 500;\n            line-height: 32px;\n            word-wrap: break-word\n        }\n        .benefit-list-description{\n            color: #6B7280;\n            font-size: 16px;\n            font-family: Plus Jakarta Sans;\n            font-weight: 400;\n            line-height: 28px;\n            word-wrap: break-word\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
