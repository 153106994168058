import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./signup.scss";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import WhiteCustomField from "../../Pages/PartnerWithUs/white-custom-field";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#38CAB5" : "#38CAB5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const SignupForm = () => {
  const [NextForm, setNextForm] = useState(false);
  const submitForm = () => {
    setNextForm(!NextForm);
  };
  return (
    <Container>
      <Box className="signup-form">
        <Typography className="welcome-title">
          Welcome to Air Applecart
        </Typography>
        <Typography className="get-started-title">Let's Get Started</Typography>
        <br />
        <Card
          className="sign-form-card"
          variant="outlined"
        >
          {!NextForm ? (
            <Box>
              <label className='input-label'>
                Full Name <span className="form-esteric">*</span>
              </label>
              <input className="input-field" placeholder="Enter Full Name" />
              <br />
              <label className='input-label'>
                Email <span className="form-esteric">*</span>
              </label>
              <input className="input-field" placeholder="Enter Email" />
              <br />
              <label className='input-label'>
                Company Registration Number (CRN){" "}
                <span className="form-esteric">*</span>
              </label>
              <input className="input-field" placeholder="Enter CRN Number" />
              <br />
              <label className='input-label'>organisation Name</label>
              <input className="input-field" placeholder="Enter organisation Name" />
              <br />
              <label className='input-label'>No of Employees </label>
              <select>
                <option value={""}> Select </option>
                <option value={"10 - 50"}> 10 - 50 </option>
                <option value={"50 - 100 "}> 50 - 100 </option>
                <option value={"100 - 150"}> 100 - 150 </option>
                <option value={"150 - 200"}> 150 - 200 </option>
                <option value={"200 - 250"}> 200 - 250 </option>
              </select>
              <Box sx={{ display: "flex" }}>
                <FormGroup sx={{ display: "contents" }}>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1, mr: 0 }} defaultChecked />}
                  />
                </FormGroup>
                <p className="toggle-text">
                  Verify your employees through Identity Gram and Get 10%
                  discount
                </p>
              </Box>
              <label className='input-label'>
                Phone Number <span className="form-esteric">*</span>
              </label>
              <input className="input-field" placeholder="Enter Number" />
              <br />
            </Box>
          ) : (
            <Box>
              <label className='input-label'>
                Select Product(s) <span className="form-esteric">*</span>
              </label>
              <Grid container>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          sx={{
                            color: '#5DD3C1',
                            "&.Mui-checked": {
                              color: '#5DD3C1',
                            },
                          }}
                        />
                      }
                      label={<span className="checkbox-label">Air Sales</span>}
                    />
                  </FormGroup>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: '#5DD3C1',
                            "&.Mui-checked": {
                              color: '#5DD3C1',
                            },
                          }}
                        />
                      }
                      label={<span className="checkbox-label">Air Operations</span>}
                    />
                  </FormGroup>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: '#5DD3C1',
                            "&.Mui-checked": {
                              color: '#5DD3C1',
                            },
                          }}
                        />
                      }
                      label={<span className="checkbox-label">Air Marketer</span>}
                    />
                  </FormGroup>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: '#5DD3C1',
                            "&.Mui-checked": {
                              color: '#5DD3C1',
                            },
                          }}
                        />
                      }
                      label={<span className="checkbox-label">Air Services</span>}
                    />
                  </FormGroup>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: '#5DD3C1',
                            "&.Mui-checked": {
                              color: '#5DD3C1',
                            },
                          }}
                        />
                      }
                      label={<span className="checkbox-label">Loyality Program</span>}
                    />
                  </FormGroup>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: '#5DD3C1',
                            "&.Mui-checked": {
                              color: '#5DD3C1',
                            },
                          }}
                        />
                      }
                      label={<span className="checkbox-label">Call Center</span>}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <br/>
              <label className='input-label'>
                Delegate Reference Number (DRN) if applied
              </label>
              <input className="input-field" placeholder="Enter Email" />
              <br/>
              <label className='input-label'>
                Create Password <span className="form-esteric">*</span>
              </label>
              <WhiteCustomField fieldPlaceholder='Enter Password' endIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15.5095 12.7C15.2495 14.11 14.0995 15.26 12.6895 15.52" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9.47 14.53L2 22" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M21.9993 2L14.5293 9.47" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>} fieldType='password' />
              <p style={{ color: '#4B5563', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', lineHeight: '18px', wordWrap: 'break-word'}}>The Password must be at least 8 characters  long having 1 capital letter, 1 small letter and 1 numeric digit</p>
              <br/>
              
              <label className='input-label'>
                Confirm Password <span className="form-esteric">*</span>
              </label>
              <WhiteCustomField fieldPlaceholder='Enter Password' endIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15.5095 12.7C15.2495 14.11 14.0995 15.26 12.6895 15.52" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9.47 14.53L2 22" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M21.9993 2L14.5293 9.47" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>} fieldType='password' />
              <br/>
            </Box>
          )}
          <Button
            sx={{
              width: "100%",
              background: "#38CAB5",
              color: "#fff",
              padding: "10px",
              fontSize: '16px',
              fontFamily: 'Plus Jakarta Sans',
              fontWeight: '500',
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#38CAB5", // Change the background color on hover to light green
              },
            }}
            variant="contained"
            onClick={submitForm}
          >
            {!NextForm ? "Next" : "Submit"}
          </Button>
        </Card>
        <br />
      </Box>
    </Container>
  );
};

