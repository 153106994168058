import React from 'react'
import { Hero } from '../../Common/Products/hero-section/Hero'
import { AIR_APPLE_CART_INTRO, AIR_APPLE_CART_NAME } from '../../../config';
// import { PolicyFeature } from '../../Features/PolicyFeature/PolicyFeature'
// export const dataPolicy = [
//     {
//         id: 1,
//         heading: 'Terms and Conditions',
//         description: "Welcome to Air Applecart! These terms and conditions outline the rules and regulations for the use of Air Applecart's website, located at www.airapplecart.co.uk.",
//         list: [
//             {
//                 heading: 'Personal Information',
//                 detail: 'Name, email, and contact details you provide while signing up.'
//             },
//             {
//                 heading: 'Usage Information',
//                 detail: 'Data related to your interactions with our platform, such as pages visited and actions taken.'
//             },
//         ]
//     },
//     {
//         id: 2,
//         heading: 'How We Use Your Information',
//         description: "We use your information to: ",
//         list: [
//             {
//                 detail: 'Provide and improve our services.'
//             },
//             {
//                 detail: 'customise your experience and offer tailored content. '
//             },
//             {
//                 detail: 'Communicate with you, respond to inquiries, and provide support. '
//             },
//             {
//                 detail: 'Send you updates, promotions, and relevant content if you opt in. '
//             },
//         ]
//     },
//     {
//         id: 3,
//         heading: 'Data Protection and Security',
//         description: "We take measures to protect your data: ",
//         list: [
//             {
//                 heading: 'Data Encryption',
//                 detail: 'Your data is transmitted and stored securely using encryption protocols.'
//             },
//             {
//                 heading: 'Access Control',
//                 detail: 'Your data is accessible only to authorised personnel.'
//             },
//             {
//                 heading: 'Infrastructure Security',
//                 detail: 'We use secure hosting and data storage solutions.'
//             },
//         ]
//     },
//     {
//         id: 4,
//         heading: 'Data Sharing and Third Parties',
//         description: "We do not sell, rent, or share your data with third parties without your explicit consent. We may share your data with trusted service providers who assist us in delivering our services. ",
//     },
//     {
//         id: 5,
//         heading: 'Data Retention',
//         description: "We retain your data for as long as necessary to provide our services. You can request data deletion at any time.",
//     },
//     {
//         id: 6,
//         heading: 'Your Data Privacy Rights',
//         description: "You have the right to access, modify, or delete your data. You can also object to the processing of your data and withdraw your consent. ",
//     },
//     {
//         id: 7,
//         heading: 'Your Data Privacy Rights',
//         description: "We use cookies and similar technologies to enhance your experience and gather usage information. You can manage your cookie preferences through your browser settings.",
//     },
//     {
//         id: 8,
//         heading: 'Changes to this Data Policy',
//         description: "We may update this Data Policy to reflect changes in our practices. We will notify you of any material changes. ",
//     },
//     {
    //         id: 9,
    //         heading: 'Contact Us',
    //         greenDescription: 'info@airapplecart.co.uk.',
    //         description: "If you have questions or concerns about your data, please contact us at ",
    //         lastDesc: ' By using Air Applecart, you acknowledge and agree to the practices outlined in this Privacy Policy. Your privacy matters to us, and we are committed to ensuring the security and confidentiality of your personal information.'
    //     },
    // ]
    export const DataPolicyPage = () => {
    return (
        <>
            {/* <PolicyFeature pageData={dataPolicy} heading="Air Applecart terms and conditions" description="At Air Applecart, safeguarding your privacy and data security is of utmost importance. This Data Policy explains how we collect, use, store, and protect your information when you use our CRM platform and services. By utilizing Air Applecart, you agree to the practices outlined below. " /> */}
            <Hero hero={[{ title: 'Terms and Conditions', description: `${AIR_APPLE_CART_INTRO} 
            These terms and conditions outline the rules and regulations for the use of Air Applecart's website, located at www.airapplecart.co.uk. By accessing this website, we assume you accept these terms and conditions. Do not continue to use Air Applecart if you do not agree to take all of the terms and conditions stated on this page.` }]} page={true} />
            <div style={styles.container}>
      {/* <h1 style={styles.header}>Terms and Conditions</h1> */}
      <p style={styles.description}>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of. Any use of the above terminology or other words in the singular, plural, captilisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
      <h2 style={styles.subHeader}>Cookies</h2>
      <p style={styles.description}>We employ the use of cookies. By accessing Air Applecart, you agreed to use cookies in agreement with the Air Applecart's Privacy Policy.</p>
      <p style={styles.description}>Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
      <h2 style={styles.subHeader}>License</h2>
      <p style={styles.description}>Unless otherwise stated, Air Applecart and/or its licensors own the intellectual property rights for all material on Air Applecart. All intellectual property rights are reserved. You may access this from Air Applecart for your own personal use subjected to restrictions set in these terms and conditions.</p>
      <p style={styles.description}>You must not:</p>
      <ul>
        <li style={styles.description}>Republish material from Air Applecart</li>
        <li style={styles.description}>Sell, rent or sub-license material from Air Applecart</li>
        <li style={styles.description}>Reproduce, duplicate or copy material from Air Applecart</li>
        <li style={styles.description}>Redistribute content from Air Applecart</li>
      </ul>
      <p style={styles.description}>This Agreement shall begin on the date hereof.</p>
      <h2 style={styles.subHeader}>Hyperlinking to our Content</h2>
      <p style={styles.description}>The following organisations may link to our Website without prior written approval:</p>
      <ul>
        <li style={styles.description}>Government agencies;</li>
        <li style={styles.description}>Search engines;</li>
        <li style={styles.description}>News organisations;</li>
        <li style={styles.description}>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
        <li style={styles.description}>System wide Accredited Businesses except soliciting non-profit organisations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
      </ul>
      <p style={styles.description}>These organisations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>
      <p style={styles.description}>We may consider and approve other link requests from the following types of organisations:</p>
      <ul>
        <li style={styles.description}>commonly-known consumer and/or business information sources;</li>
        <li style={styles.description}>dot.com community sites;</li>
        <li style={styles.description}>associations or other groups representing charities;</li>
        <li style={styles.description}>online directory distributors;</li>
        <li style={styles.description}>internet portals;</li>
        <li style={styles.description}>accounting, law and consulting firms; and</li>
        <li style={styles.description}>educational institutions and trade associations.</li>
      </ul>
      <p style={styles.description}>We will approve link requests from these organisations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organisation does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Air Applecart; and (d) the link is in the context of general resource information.</p>
      <p style={styles.description}>These organisations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.</p>
      <p style={styles.description}>If you are one of the organisations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Air Applecart. Please include your name, your organisation name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
      <p style={styles.description}>Approved organisations may hyperlink to our Website as follows:</p>
      <ul>
        <li style={styles.description}>By use of our corporate name; or</li>
        <li style={styles.description}>By use of the uniform resource locator being linked to; or</li>
        <li style={styles.description}>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</li>
      </ul>
      <p style={styles.description}>No use of Air Applecart's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
      <h2 style={styles.subHeader}>iFrames</h2>
      <p style={styles.description}>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>
      <h2 style={styles.subHeader}>Content Liability</h2>
      <p style={styles.description}>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other</p>
    </div>
        </>
    )
}

const styles = {
    container: {
      maxWidth: '1150px',
      margin: 'auto',
      // marginTop: '100px',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
    },
    header: {
      marginBottom: '20px',
      fontSize: '32px', 
      fontWeight: '600', 
      color: '#1F2937'
    },
    subHeader: {
      marginTop: '20px',
      marginBottom: '10px',
      fontSize: '25px', fontWeight: '600', color: '#1F2937'
    },
    description: {
        fontSize: '18px', fontWeight: '500', color: '#6B7280'
    }
  };
