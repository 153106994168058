export const card_data = [
    {
        title: 'CRM Suite',
        description: 'Ideal for startups and small businesses. Access essential marketing tools, audience insights, and basic reporting.',
        users: '20',
        next_billing: 'Sep 24, 2023',
        estimate: '£115',
        plan: false,
    },
    {
        title: 'Air Marketer',
        description: 'Air Marketer is your comprehensive solution for executing successful marketing campaigns and achieving your business objectives.',
        users: '20',
        next_billing: 'Sep 24, 2023',
        estimate: '£115',
        plan: true,
    },
    {
        title: 'Air Sales',
        description: `Air Sales is your ultimate partner for sales success.We're dedicated to helping you elevate your sales strategies and achieve remarkable growth`,
        users: '20',
        next_billing: 'Sep 24, 2023',
        estimate: '£115',
        plan: false,
    },
    {
        title: 'Air Services',
        description: `Air Services is your gateway to delivering exceptional client services.Our commitment is to empower your team to provide top-tier services and ensure utmost client satisfaction.`,
        users: '20',
        next_billing: 'Sep 24, 2023',
        estimate: '£115',
        plan: true,
    },
    {
        title: 'Air Operations',
        description: `Air Operations is your trusted partner in revolutionising operational excellence. Our commitment lies in enhancing your organisation's efficiency, productivity, and collaboration. `,
        users: '20',
        next_billing: 'Sep 24, 2023',
        estimate: '£115',
        plan: false,
    },
    {
        title: 'Calendar Piper',
        description: `Calendar Piper is an innovative addition to Air Applecart that empowers you to effortlessly manage your time, appointments, and engagements.`,
        users: '20',
        next_billing: 'Sep 24, 2023',
        estimate: '£115',
        plan: false,
    },
    {
        title: 'Fiat Sign',
        description: `Fiat Sign is the contract management solution your business needs to navigate the intricacies of contract management seamlessly.`,
        users: '20',
        next_billing: 'Sep 24, 2023',
        estimate: '£115',
        plan: false,
    },
    // {
    //     title: 'Stenogofer',
    //     description: `Elevate your communication and collaboration game with Stenogofer, seamlessly integrated into Air Applecart.`,
    //     users: '20',
    //     next_billing: 'Sep 24, 2023',
    //     estimate: '£115',
    //     plan: false,
    // },
    // {
    //     title: 'MLM',
    //     description: `Experience the evolution of marketing with Air Applecart's MLM. Our MLM module equips you with the capabilities to build and manage a thriving network of distributors.`,
    //     users: '20',
    //     next_billing: 'Sep 24, 2023',
    //     estimate: '£115',
    //     plan: false,
    // },
    // {
    //     title: 'Grammar Tok',
    //     description: `With Grammar Tok you can  master the art of effective writing. Whether you're a student, professional, or content creator, It helps you achieve your communication goals with ease.`,
    //     users: '20',
    //     next_billing: 'Sep 24, 2023',
    //     estimate: '£115',
    //     plan: false,
    // },
];

export const billing_card_data = [
    {
        id: 1,
        title: 'Air Marketer',
        paid: 'Monthly',
        pricing_details: [
            {   
                id: 1,
                detail: 'Plan Price',
                price: '£20',
            },
            {
                id: 2,
                detail: '3 Additional Users (£45/user)',
                price: '£45',
            },
            {
                id: 3,
                detail: 'Additional Storage (£1/GB)',
                price: '£1',
            },
            {
                id: 4,
                detail: 'Discount (10%)',
                price: '-£10',
            },
            {
                id: 5,
                detail: 'Tax (20%)',
                price: '£27',
            },
        ],
    },
    {
        id: 2,
        title: 'Air Services',
        paid: 'Monthly',
        pricing_details: [
            {   
                id: 1,
                detail: 'Plan Price',
                price: '£20',
            },
            {
                id: 2,
                detail: '3 Additional Users (£45/user)',
                price: '£45',
            },
            {
                id: 3,
                detail: 'Additional Storage (£1/GB)',
                price: '£1',
            },
            {
                id: 4,
                detail: 'Discount (10%)',
                price: '-£10',
            },
            {
                id: 5,
                detail: 'Tax (20%)',
                price: '£27',
            },
        ],
    },
    {
        id: 3,
        title: 'Air Sales',
        paid: 'Anually',
        pricing_details: [
            {   
                id: 1,
                detail: 'Plan Price',
                price: '£20',
            },
            {
                id: 2,
                detail: '3 Additional Users (£45/user)',
                price: '£45',
            },
            {
                id: 3,
                detail: 'Additional Storage (£1/GB)',
                price: '£1',
            },
            {
                id: 4,
                detail: 'Discount (10%)',
                price: '-£10',
            },
            {
                id: 5,
                detail: 'Tax (20%)',
                price: '£27',
            },
        ],
    },
];